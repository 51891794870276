<template>
  <section v-if="business_doc">
    <div v-if="plan_change">
      <Complete :plan_change="plan_change" />
    </div>
    <div v-else>
      <h1 class="is-size-4 has-text-weight-bold py-5">決済を停止する</h1>

      <p>対象事業所</p>
      <p class="mb-5">{{ business_doc["business_name"] }} {{ business_doc["branch_name"] }}</p>

      <div
        v-if="payment_date"
        class="has-text-danger"
      >
        <p>{{ expired(charges_doc.last_payment_date) }}を過ぎると、ご利用いただける機能が制限されます。</p>
        <br>
        <p>また、転送用URLは削除となります。</p>
        <p>チラシやWebサイト、各種SNSなどに転送用URLをご利用の場合はリンク切れになるため変更が必要です。</p>
      </div>

      <div class="col s12 place-order-button-block">
        <b-button
          v-if="success === false"
          class="btn col s12 #e91e63 pink"
          type="is-success has-text-weight-bold"
          @click="planDowngrade()"
        >
          決済を停止する
        </b-button>
        <b-button v-if="success === 'loading'" loading>Loading</b-button>
        <b-loading
          :is-full-page="isFullPage"
          :active.sync="isLoading"
        ></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Complete from '@/components/payment/Complete.vue'
import moment from 'moment'
import Config from '@/config.js'

export default {
  components: {
    Complete
  },
  data () {
    return {
      business_doc: this.$store.getters.business_data,
      charges_docs: this.$store.getters.charges_docs,
      charges_doc: {},
      success: false,
      isLoading: false,
      isFullPage: true,
      plan_change: '',
    }
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getChargesDocsData() {
      return this.$store.getters.charges_docs;
    },
    payment_date () {
      if(!this.charges_docs) {
        return false
      }
      // 一件でもpayment_dataが入っていたらtrueをreturn
      const is_payment_date = Object.keys(this.charges_docs).find((pageId) => {
          return (this.charges_docs[pageId].gs && this.charges_docs[pageId].gs.payment_date !== "")
            || (this.charges_docs[pageId].gd && this.charges_docs[pageId].gd.payment_date !== "")
      })
      return is_payment_date ? true : false
    },
  },
  methods: {
    // 入力された日付に31日を加算して返してYMD形式に変換する
    expired (date) {
      return date ? moment(new Date(date).toISOString()).add(31, 'days').format('YYYY-MM-DD') : ''
    },
    // 決済停止処理
    async planDowngrade () {
      try {
        // chargesデータを変更する
        // 更新のdispatch
        await this.$store.dispatch('updateChargesData', {
          doc_id : this.business_doc.business_id,
          data : {
            page: {
              payment_date : ''
            }
          }
        })

      } catch(e) {
          this.$swal('error', '保存エラー：' + e, 'error')
          return
      }

      // 完了画面を表示させる
      this.plan_change = 'downgrade';

      this.success = await true;
      this.isLoading = await false;

      // 無料プランへ戻したことをメールする
      await this.PlanDowngradeMailToClient()
    },

    /**
     * プラン変更メール送信
     */
    async PlanDowngradeMailToClient() {
      let data = {
        template_id : 'MANUAL_CHANGE_PLAN_FREE_TO_CLIENT_SUCCESS',
        to: this.$store.state.user.email,
        email_auth: this.$store.state.user.email,
        business_name: this.business_doc.business_name,
        branch_name: this.business_doc.branch_name || '',
        category: this.business_doc.category,
        last_payment_date: this.chargesdata.page.last_payment_date,
        expiration_date: moment(new Date(this.chargesdata.page.last_payment_date).toISOString()).add(31, 'days').format('YYYY/MM/DD'),
        status: 'sending',
        date: moment().format("YYYY-MM-DD HH:MM:SS"),
        business_id: this.business_doc.business_id,
        name: this.$store.state.admin_data.service_name.page.name,
        short_name: this.$store.state.admin_data.service_name.page.short_name,
        system_name: this.$store.state.admin_data.service_name.page.system_name,
        country_code: this.$store.state.admin_data.service_name.url.country_code,
        tld: this.$store.state.admin_data.service_name.url.tld,
      }
      await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    },

  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
  },
}

</script>
