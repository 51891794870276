<template>
  <section v-if="page.voice">
    <div>
        <h2 class="is-size-4 has-text-weight-bold pb-0">スタッフの声</h2>
    </div>
    <hr class="py-0 my-1">
    <div class="mb-5">
      <p v-html="title.voice_description"></p>
    </div>
    <div class="content" v-for="key of page.voice.length" :key="key">
      <div class="move-panel">
      <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
      <template #trigger>
          <b-icon icon="dots-vertical"></b-icon>
      </template>
          <b-dropdown-item aria-role="listitem" @click="menuMove('voice', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="menuMove('voice',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="menuMove('voice',(key-1),'down')" :disabled="(key-1) === (page.voice.length - 1)">一つ下に移動</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="menuMove('voice',(key-1),'down', true)" :disabled="(key-1) === (page.voice.length - 1)">一番下に移動</b-dropdown-item>
      </b-dropdown>
      </div>

      <b-collapse
      aria-id="contentIdForA15y5"
      class=""
      animation="slide"
      v-model="isOpen">
      <template #trigger>
          <div
          class="panel-heading mb-3"
          role="button"
          aria-controls="contentIdForA15y5">
          <strong v-if="page.voice[key-1]['title']">{{ page.voice[key-1]['title']}}</strong>
          <strong v-else>スタッフの声{{ key }}のタイトルを入力してください</strong>
          </div>
      </template>
      <div class="field pb-3">
          <label class="label has-text-weight-bold" :for="'voice_title_' + key">スタッフの声{{ key }}のタイトル </label>
          <b-input
          :id="'voice_title_' + key"
          v-model="page.voice[key-1]['title']"
          maxlength="30"
          @blur="trim('voice', key-1, 'title')"
          >
          </b-input>
      </div>

      <div v-if="updateFlg" class="field pb-3">
        <label class="label has-text-weight-bold" :for="'voice_image_' + key">&nbsp;スタッフの声{{ key }}の画像</label>
        <div class="columns">
        <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
            <img :src="page.voice[key-1]['image']" width="100px">
        </div>
        <div class="column">
            <photo-resize :image-title="'voice_image'"
            :doc-field-image-title="'image'"
            :array-num="key-1"
            :doc-field-title="'voice'"
            :page-id="page.page_id"
            :src="page.voice[key-1]['image']"
            @voiceimage="imgUpdate" ref="voiceimage"/>
        </div>
        </div>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" :for="'voice_detail_' + key">&nbsp;スタッフの声{{ key }}の詳細</label>
        <b-input
        :id="'voice_detail_' + key"
        v-model="page.voice[key-1]['detail']"
        maxlength="150"
        type="textarea"
        @blur="trim('voice', key-1, 'detail')"
        >
        </b-input>
      </div>
      </b-collapse>
  </div>
  </section>
</template>

<script>
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  data () {
    return {
      page : this.$store.getters.page_data,
      isOpen: true,
      updateFlg: true,
      imageFile: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods :{
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.page, firstProp, this.page[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.page[firstProp], secondProp, this.page[firstProp][secondProp].trim())
      } else {
        this.$set(this.page[firstProp][secondProp], thirdProp, this.page[firstProp][secondProp][thirdProp].trim())
      }
    },
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.voiceimage).map(async key => {
        this.$refs.voiceimage[key].reset()
      })
    }

  },
  computed : {
    title () {
      return this.$store.getters.title
    },
    // page_dataの監視
    getPageData() {
      return this.$store.getters.page_data && Object.keys(this.$store.getters.page_data).length
      ? this.$store.getters.page_data
      : this.$store.getters.admin_data.default_page_data
    },
  },
  watch : {
    // page_dataの監視
    getPageData(newValue) {
      if(newValue === undefined) return
      this.page = newValue;
    },
  },
}
</script>

<style scoped>
.dropdown {
  top: 13px;
  left: -5px;
}
</style>
