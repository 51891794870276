<template>
  <!-- storeからの反映が少し遅れるのでifで待つ -->
  <section>
    <div>
      <h2 class="is-size-4 has-text-weight-bold pb-0">事業所情報</h2>
    </div>
    <hr class="py-0 my-1">

    <div class="mb-5">
      <p v-html="business_title.basic_info_description"></p>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="category_group"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.category_group }}</label>
      <p class="pb-1">
        {{ business_title.category_group_description }}
        <br>
        <a href="https://docs.google.com/spreadsheets/d/1Ee-bgiuk_yY632l5AuIIRVZCam-PhjPvMn6zU9Me0xg/edit#gid=0"
          target="_blanc">業種の一覧を見る&nbsp;<span class="icon-external-link"></span></a>
      </p>
      <div class="select">
        <b-select id="category_group" v-model="business_doc.category_group" placeholder="選択してください">
          <option value="" disabled>選択してください</option>
          <option v-for="(value, key) in category_group" :key="key" :value="value">{{ value }}</option>
        </b-select>
      </div>
    </div>

    <div class="field pb-4">
      <label class="label has-text-weight-bold" for="category"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.category }}</label>
      <p class="pb-1" v-html="business_title.category_description"></p>
      <div v-if="business_doc.category_group === `その他`">
        <b-input id="category" v-model="business_doc.category" maxlength="15" @blur="trim('category')" />
      </div>
      <div v-else-if="business_doc.category_group" class="select">
        <b-select id="category" v-model="business_doc.category" placeholder="選択してください">
          <option value="" disabled>選択してください</option>
          <option v-for="(value, key) in categories" :key="key" :value="value">{{ value }}</option>
        </b-select>
      </div>
      <div v-else>
        <b-select id="category" placeholder="選択してください" disabled></b-select>
      </div>
    </div>

    <div class="field py-3">
      <label class="label has-text-weight-bold" for="business_name"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.business_name }}</label>
      <p v-html="business_title.business_name_description"></p>
      <input id="business_name" class="input" v-model="businessName" @focus="onFocus('business_name')">
      <small v-if="business_name_focus" class="help counter business-name-counter">
        {{ business_name_len }} / 25
      </small>
      <div v-if="isFormCompleted">
        <p><span class="has-text-danger">半角25文字（全角12文字）以内で入力してください</span></p>
      </div>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_name_ja">{{ business_title.business_name_ja }}</label>
      <p v-html="business_title.business_name_ja_description"></p>
      <b-input id="business_name_ja" v-model="business_doc.business_name_ja" maxlength="20"
        @blur="trim('business_name_ja')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="branch_name">
        {{ business_title.branch_name }}
      </label>
      <p v-html="business_title.branch_name_description"></p>
      <b-input id="branch_name" v-model="business_doc.branch_name" maxlength="20" class="pb-1"
        @blur="trim('branch_name')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="phone"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.phone }}</label>
      <p v-html="business_title.phone_description"></p>
      <b-field :type="errors.has('phone') ? 'is-danger' : ''"
        :message="errors.has('phone') ? errors.first('phone') : ''">
        <b-input name="phone" v-model="business_doc.phone"
          v-validate="{ required: true, regex: /^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/ }" data-vv-as="電話番号">
        </b-input>
      </b-field>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="email"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.email }}</label>
      <p v-html="business_title.email_description"></p>
      <b-field :type="errors.has('email') ? 'is-danger' : ''" :message="errors.has('email') ? customMessage : ''">
        <b-input name="email" v-model="business_doc.email" v-validate="{ required: true, regex: regex_email, email: true }"
          data-vv-as="メールアドレス">
        </b-input>
      </b-field>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="zip_code"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.zip_code }}</label>
      <p v-html="business_title.zip_code_description"></p>
      <b-field :type="errors.has('zip_code') ? 'is-danger' : ''"
        :message="errors.has('zip_code') ? errors.first('zip_code') : ''">
        <b-input name="zip_code" id="zip_code" v-model="business_doc.zip_code" @input="fetchAddress"
          v-validate.immediate="{ regex: /^[0-9]{3}-[0-9]{4}$/ }" data-vv-as="郵便番号">
        </b-input>
      </b-field>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="region"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.region }}</label>
      <b-input id="region" v-model="business_doc.region" readonly></b-input>
    </div>


    <div class="field">
      <label class="label has-text-weight-bold" for="city"><span class="tag is-danger">必須</span>&nbsp;{{
          business_title.city }}</label>
      <b-input id="city" v-model="business_doc.city" readonly></b-input>
    </div>


    <div class="field">
      <label class="label has-text-weight-bold" for="street_address"><span class="tag is-danger">必須</span>&nbsp;
        {{ business_title.street_address }}
      </label>
      <p v-html="business_title.street_address_description"></p>
      <b-input id="street_address" v-model="business_doc.street_address" @blur="trim('street_address')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="building_name">{{ business_title.building_name }}</label>
      <p v-html="business_title.building_name_description"></p>
      <b-input id="building_name" v-model="business_doc.building_name" maxlength="30"
        @blur="trim('building_name')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="station">{{ business_title.station }}</label>
      <p v-html="business_title.station_description"></p>
      <b-input id="station" v-model="business_doc.station" maxlength="15" @blur="trim('station')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="access">{{ business_title.access }}</label>
      <p v-html="business_title.access_description"></p>
      <b-input id="access" v-model="business_doc.access" maxlength="10" @blur="trim('access')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="line_id">{{ business_title.line_id }}</label>
      <p v-html="business_title.line_id_description"></p>
      <b-input id="line_id" v-model="business_doc.line_id" @blur="trim('line_id')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_summary">{{ business_title.business_summary }}</label>
      <p v-html="business_title.business_summary_description"></p>
      <b-input id="business_summary" v-model="business_doc.business_summary" type="textarea" maxlength="300"
        @blur="trim('business_summary')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_detail_title">{{ business_title.business_detail_title
        }}</label>
      <p v-html="business_title.business_detail_title_description"></p>
      <b-input id="business_detail_title" v-model="business_doc.business_detail_title" maxlength="15"
        @blur="trim('business_detail_title')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_detail">{{ business_title.business_detail }}</label>
      <p v-html="business_title.business_detail_description"></p>
      <b-input id="business_detail" v-model="business_doc.business_detail" type="textarea" maxlength="500"
        @blur="trim('business_detail')"></b-input>
    </div>

    <BusinessImage @imgUpdate="imgUpdate" ref="businessimage" />

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_movie">{{ business_title.business_movie }}</label>
      <p v-html="business_title.business_movie_description"></p>
      <b-input id="business_movie" v-model="business_doc.business_movie" @blur="trim('business_movie')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="contact_message">{{ business_title.contact_message }}</label>
      <p v-html="business_title.contact_message_description"></p>
      <b-input id="contact_message" v-model="business_doc.contact_message" type="textarea" maxlength="300"
        @blur="trim('contact_message')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="site_policy_url">{{ business_title.site_policy_url }}</label>
      <p v-html="business_title.site_policy_url_description"></p>
      <b-input id="site_policy_url" v-model="business_doc.site_policy_url" @blur="trim('site_policy_url')"></b-input>
    </div>
  </section>
</template>

<style scoped>
.business-name-counter {
  float: right;
  margin-left: .5em;
}
</style>

<script>
import BusinessImage from '@/components/business/BusinessImage.vue';
import { category_group, category } from '../../const.js'
import Config from '../../config.js'
import axiosJsonpAdapter from 'axios-jsonp'
import axios from 'axios'

export default {
  data() {
    return {
      business_doc: this.$store.getters.business_data || {
        category_group: '',
        category: '',
        business_name: '',
        business_name_ja: '',
        branch_name: '',
        phone: '',
        email: '',
        zip_code: '',
        region: '',
        city: '',
        street_address: '',
        building_name: '',
        station: '',
        access: '',
        line_id: '',
        business_summary: '',
        business_detail_title: '',
        business_detail: '',
        business_movie: '',
        contact_message: '',
        site_policy_url: '',
      },
      isFormCompleted: false,
      business_name_focus: false,
      category_group: category_group,
      category: category,
      regex_email: /^[\x20-\x7e]*$/,
      customMessage: 'メールアドレスのフォーマットが正しくありません',
    }
  },
  components: {
    BusinessImage,
  },
  methods: {
    onFocus: function (field) {
      this[field + '_focus'] = true
    },
    // テキスト入力のtrim処理
    trim(firstProp, secondProp = null, thirdProp = null) {
      if (secondProp === null) {
        this.$set(this.business_doc, firstProp, this.business_doc[firstProp].trim())
      } else if (thirdProp === null) {
        this.$set(this.business_doc[firstProp], secondProp, this.business_doc[firstProp][secondProp].trim())
      } else {
        this.$set(this.business_doc[firstProp][secondProp], thirdProp, this.business_doc[firstProp][secondProp][thirdProp].trim())
      }
    },
    // business_nameの文字列制限用
    charaCountAndLimitStr(str, set_len) {
      let len = 0
      let s = str.length
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/))
          ? len += 1
          : !str[i].match(/^[ｦ-ﾟ]*$/)
            ? len += 2
            : len += 1;
        if (len > set_len) {
          this.isFormCompletedTrue()
          s = i
          break
        }
      }
      return { len: len, str: str.slice(0, s) }
    },
    isFormCompletedTrue() {
      this.isFormCompleted = true
      setTimeout(() => {
        this.isFormCompleted = false
      }
        , 3000
      )
    },
    /**
     * 郵便番号からの住所検索
     */
    async fetchAddress() {
      // 郵便番号のバリデーションチェック
      const reg = /^\d{3}-\d{4}$/g
      let zipcode = this.business_doc.zip_code

      if (!reg.test(zipcode)) {
        this.business_doc.region = ''
        this.business_doc.city = ''
        return
      }

      zipcode = zipcode.replace(/-/g, '')

      // 住所apiを叩く
      await axios.get(Config.ZIPCODE_API_URL + '/api/search', {
        adapter: axiosJsonpAdapter,
        params: {
          zipcode: zipcode,
        }
      }).then((res) => {
        // リクエストが失敗した時は200以外のステータスが返ってくる
        if (res.status !== 200) return

        if (res.data.code === undefined) {
          // 返却されたデータを挿入する
          this.$nextTick(function () {
            this.business_doc.region = res.data.results[0].address1
            this.business_doc.city = res.data.results[0].address2
            this.business_doc.street_address = res.data.results[0].address3
          })
        } else {
          // 結果がない場合はNULL値で上書き
          this.business_doc.region = ''
          this.business_doc.city = ''
          this.business_doc.street_address = ''
          return
        }
      }
      )
    },
    // photoResize→親へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.$emit('imgUpdate', imageFileData);
    },
    // 親→photoResize.reset(画面上の画像表示削除させる)
    reset: function () {
      // console.log('BusinessInfo', this.$refs.businessimage);
      this.$refs.businessimage.reset()
    }
  },
  computed: {
    business_title() {
      return this.$store.getters.business_title
    },
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data && Object.keys(this.$store.getters.business_data).length
        ? this.$store.getters.business_data
        : this.$store.getters.admin_data.business_data
    },
    categories() {
      return this.category[this.business_doc.category_group]
    },
    // business_nameの文字カウント（表示用）
    business_name_len() {
      return this.charaCountAndLimitStr(this.business_doc["business_name"], 25)['len']
    },
    // business_nameの入力時（文字上限用）
    businessName: {
      get() {
        return this.business_doc["business_name"]
      },
      set(value) {
        this.business_doc["business_name"] = this.charaCountAndLimitStr(value, 25)['str']
      }
    },
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      if (newValue === undefined) return
      this.business_doc = newValue;
    },
    // 画面上でのbusiness_docの変更をstoreにcommit
    business_doc: {
      handler: function (newVal) {
        this.$store.commit('business_data', newVal)
      },
      deep: true
    }
  },
}
</script>
