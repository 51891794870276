<template>
  <!-- storeからの反映が少し遅れるのでifで待つ -->
  <section v-if="business_doc.qa">
    <div>
        <h2 class="is-size-4 has-text-weight-bold pb-0">よくある質問</h2>
    </div>
    <hr class="py-0 my-1">
    <div class="mb-5">
      <p v-html="business_title.qa_description"></p>
    </div>
    <div v-if="updateFlg">
      <div class="content" v-for="key of business_doc.qa.length" :key="key">
        <div class="move-panel">
        <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
        <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
        </template>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('qa', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('qa',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('qa',(key-1),'down')" :disabled="(key-1) === (business_doc.qa.length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('qa',(key-1),'down', true)" :disabled="(key-1) === (business_doc.qa.length - 1)">一番下に移動</b-dropdown-item>
        </b-dropdown>
        </div>

        <b-collapse
        aria-id="contentIdForA13y3"
        class=""
        animation="slide"
        v-model="isOpen">
        <template #trigger>
            <div
            class="panel-heading mb-3"
            role="button"
            aria-controls="contentIdForA13y3">
            <strong v-if="business_doc.qa[key-1]['question']">{{ business_doc.qa[key-1]['question']}}</strong>
            <strong v-else>よくある質問{{key}}の質問を入力してください</strong>
            </div>
        </template>
        <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'qa_question_' + key">よくある質問{{key}}の質問</label>
            <p class="pb-1" v-if="(key-1) === 0">
            例）予約は必要ですか？
            </p>
            <b-input
            :id="'qa_question_' + key"
            v-model="business_doc.qa[key-1]['question']"
            maxlength="30"
            @blur="trim('qa', key-1, 'question')"
            >
            </b-input>
        </div>

        <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'qa_answer_' + key">よくある質問{{key}}の回答</label>
            <p class="pb-1" v-if="(key-1) === 0">
            例）はい。当店は完全予約制となっていますので、お電話やLINE、予約フォームからご予約をお願いします。
            </p>
            <b-input
            :id="'qa_answer_' + key"
            v-model="business_doc.qa[key-1]['answer']"
            maxlength="100"
            type="textarea"
            @blur="trim('qa', key-1, 'answer')"
            >
            </b-input>
        </div>

        </b-collapse>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  data () {
    return {
      business_doc: this.$store.getters.business_data || {
        qa: [
          {
            question: '',
            answer: ''
          }
        ]

      },
      isOpen: true,
      updateFlg: true,
    }
  },
  computed : {
    business_title () {
      return this.$store.getters.business_title
    },
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data && Object.keys(this.$store.getters.business_data).length
      ? this.$store.getters.business_data
      : this.$store.getters.admin_data.default_business_data
    },
  },
  watch : {
    // business_dataの監視
    getBusinessData(newValue) {
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
  },
  methods :{
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.business_doc, firstProp, this.business_doc[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.business_doc[firstProp], secondProp, this.business_doc[firstProp][secondProp].trim())
      } else {
        this.$set(this.business_doc[firstProp][secondProp], thirdProp, this.business_doc[firstProp][secondProp][thirdProp].trim())
      }
    },
  }
}
</script>

<style scoped>
.dropdown {
  top: 13px;
  left: -5px;
}
</style>
