<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu />
    </div>

    <div class="column">
      <h1 class="is-size-4 has-text-weight-bold py-1">チラシの作成</h1>

      <p>下記より作成したいチラシを選択してください。
        <span class="is-hidden-desktop">
          右にスワイプできます。
        </span>
      </p>
      <hr>

      <div>
        <h2 class="is-size-5 has-text-weight-bold">事業所用のチラシの作成</h2>
      </div>

      <div class="mb-3">
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbjobs-jp/business_flyer.jpg" alt="flyer画像" width="50%">
      </div>
      <div class="mb-3">
        <p>
          <a href="https://r.ilb.net/lrm-flyer-business-sample-ja" target="_blank">「事業者用チラシ」の制作事例を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
        </p>
      </div>
      <div v-if="business_doc.business_id">
        <div class="mb-3">
          <p>
            下記のボタンをクリックすると事業所用チラシを作成できます。
          </p>
          <b-button
            type="is-primary"
            @click="print('business')"
          >
            チラシを作成する
          </b-button>
        </div>
        <div class="mb-3">
          <p>
            必ずデータ（PDF）をダウンロードして印刷してください。
          </p>
          <p>
            <a href="https://r.ilb.net/flyer-print-ja" target="_blank">チラシ印刷時の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
          </p>
          <p>
            <a href="https://r.ilb.net/flyer-pdf-ja" target="_blank">チラシ印刷を業者に依頼する場合の注意点を見る（PDFの作成方法）&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
          </p>
        </div>
      </div>
      <div v-else>
        <p class="has-text-danger">
          ビジネスデータが未設定です
        </p>
      </div>

      <hr>

      <div>
        <h2 class="is-size-5 has-text-weight-bold">求人用チラシの作成</h2>
      </div>

      <div class="mb-3">
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbjobs-jp/recruit_flyer.jpg" alt="flyer画像" width="50%">
      </div>
      <div class="mb-3">
        <p>
          <a href="https://r.ilb.net/lrm-flyer-recruit-sample-ja" target="_blank">「求人用チラシ」の制作事例を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
        </p>
      </div>
      <div v-if="Object.keys(page_docs).length">
        <div class="mb-3">
          <p>
            作成するチラシの職種を選択してください。
          </p>
          <div class="select">
            <b-select id="employment_type" v-model="selected_page_id" placeholder="選択してください">
              <option v-for="(value, index) in jobTypeEmploymentTypeList" :key="index" :value="value.page_id">{{ value.display_name }}</option>
            </b-select>
          </div>
        </div>
        <div class="mb-3">
          <p>
            下記のボタンをクリックすると求人用チラシを作成できます。
          </p>
          <b-button
            type="is-primary"
            @click="print('page')"
          >
            チラシを作成する
          </b-button>
        </div>
        <div class="mb-3">
          <p>
            必ずデータ（PDF）をダウンロードして印刷してください。
          </p>
          <p>
            <a href="https://r.ilb.net/flyer-print-ja" target="_blank">チラシ印刷時の注意点を見る&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
          </p>
          <p>
            <a href="https://r.ilb.net/flyer-pdf-ja" target="_blank">チラシ印刷を業者に依頼する場合の注意点を見る（PDFの作成方法）&nbsp;<b-icon icon="launch" size="is-small"></b-icon></a>
          </p>
        </div>
      </div>
      <div v-else>
        <p class="has-text-danger">
          求人データが未設定です
        </p>
      </div>
    </div>

  </div>

</section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'

export default {
  components: {
    Menu,
  },
  data () {
    return {
      business_doc: this.$store.getters.business_data || {},
      page_docs: this.$store.getters.page_docs || {},
      page : {},
      selected_page_id : null,
      jobTypeEmploymentTypeList : [
        {
          page_id : '',
          display_name :''
        }
      ],
    }
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getPageDocsData() {
      return this.$store.getters.page_docs;
    },
  },
  methods: {
    print(type) {
      switch(type) {
        case 'business' :
          // 事業所用のチラシのページを新規タブで開く
          window.open('https://app.ilb.net/flyer/lrm/jp/business.html?id=' + this.business_doc.business_id, '_blank')
          break;

        case 'page' :
          // 求人用のチラシのページを新規タブで開く
          window.open('https://app.ilb.net/flyer/lrm/jp/page.html?id=' + this.selected_page_id, '_blank')
          break;
      }
    },
    setJobTypeEmploymentTypeList (page_docs) {
      this.jobTypeEmploymentTypeList = Object.keys(page_docs).map((page_id) => {
        return {
          page_id : page_id,
          display_name : page_docs[page_id].job_type + '：' + this.employmentTypeJa(page_docs[page_id].employment_type)
        }
      })
    }
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
    // page_docsの監視
    getPageDocsData(newValue) {
      this.page_docs = newValue;
      // listにセット
      this.setJobTypeEmploymentTypeList(newValue)
    },
    selected_page_id (newValue) {
      this.page = this.page_docs[newValue]
    },
  },
  mounted() {
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.closing-rate-details-check-item img {
  border: none;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-right: 5px;
  vertical-align: text-top;
}
.check-item{
  margin-bottom: 20px;
}

</style>
