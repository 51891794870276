export default{
    methods: {
      menuMove(field, key, type, flag = false) {
        let src = []
        let dest = []

        switch (type) {
          // 上に上げる
          case 'up':
            // 一番上なら動作しない（メニューにも出さない）
            if(key !== 0) {
              // 一番上に
              if(flag) {
                // 配列の入れ替え
                this.page[field].unshift(this.page[field][key]);
                this.page[field].splice(key + 1, 1);
              } else {
                // 一つ上に
                src = this.page[field][key]
                dest = this.page[field][key-1]
                this.page[field][key - 1] = src
                this.page[field][key] = dest
              }
            }
            break;

          // 下に下げる
          case 'down':
            // 一番下なら動作しない（メニューにも出さない）
            if(key !== (this.page[field].length - 1)) {
              // 一番下に
              if(flag) {
                // 配列の入れ替え
                this.page[field].push(this.page[field][key]);
                this.page[field].splice(key, 1);
              } else {
                // 一つ下に
                src = this.page[field][key]
                dest = this.page[field][key + 1]
                this.page[field][key + 1] = src
                this.page[field][key] = dest
              }
            }
            break;
          default:
            break;
        }
        // console.log(this.page[field])
        this.updateFlg = Math.random()
      },

      menuMoveBusiness(field, key, type, flag = false) {
        let src = []
        let dest = []

        switch (type) {
          // 上に上げる
          case 'up':
            // 一番上なら動作しない（メニューにも出さない）
            if(key !== 0) {
              // 一番上に
              if(flag) {
                // 配列の入れ替え
                this.business_doc[field].unshift(this.page[field][key]);
                this.business_doc[field].splice(key + 1, 1);
              } else {
                // 一つ上に
                src = this.business_doc[field][key]
                dest = this.business_doc[field][key-1]
                this.business_doc[field][key - 1] = src
                this.business_doc[field][key] = dest
              }
            }
            break;

          // 下に下げる
          case 'down':
            // 一番下なら動作しない（メニューにも出さない）
            if(key !== (this.business_doc[field].length - 1)) {
              // 一番下に
              if(flag) {
                // 配列の入れ替え
                this.business_doc[field].push(this.business_doc[field][key]);
                this.business_doc[field].splice(key, 1);
              } else {
                // 一つ下に
                src = this.business_doc[field][key]
                dest = this.business_doc[field][key + 1]
                this.business_doc[field][key + 1] = src
                this.business_doc[field][key] = dest
              }
            }
            break;
          default:
            break;
        }
        // storeにコミット
        this.updateFlg = Math.random()
      },
      employmentTypeJa(str) {
        switch(str) {
          case 'FULL_TIME':
            return '正社員';
          case 'PART_TIME':
            return 'アルバイト・パート';
          case 'CONTRACTOR':
            return '契約社員';
          case 'OUTSOURCE':
            return '業務委託';
          case 'INTERN':
            return 'インターン';
          case 'VOLUNTEER':
            return 'ボランティア';
          default:
            return str;
        }
      },
    }
}
