var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('Menu')],1),_c('div',{staticClass:"column"},[_c('h1',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v(_vm._s(_vm.business_doc.business_name + ' ' + _vm.business_doc.branch_name)+"問合せ内容の一覧")]),_c('p',{staticClass:"mb-3"},[_vm._v("メールフォームから送信された問合せ内容の一覧です。ページごとの絞り込み、問合せ日時での並べ替えや問合せ内容などでの検索ができます。")]),_c('hr'),_c('p',[_vm._v("問合せ内容を表示するページを選択してください")]),_c('div',{staticClass:"select mb-3"},[_c('b-select',{attrs:{"id":"contact_list","placeholder":"選択してください"},model:{value:(_vm.selected_id),callback:function ($$v) {_vm.selected_id=$$v},expression:"selected_id"}},_vm._l((_vm.contactList),function(value,index){return _c('option',{key:index,domProps:{"value":value.id}},[_vm._v(_vm._s(value.display_name))])}),0)],1),_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
            enabled: true,
            skipDiacritics: true,
            placeholder: '名前、電話番号、メールアドレス、お問合せ内容で検索ができます',
          },"pagination-options":{
            enabled: true,
            setCurrentPage: 1,
            mode: 'records',
            perPage: 20,
            dropdownAllowAll: false,
            jumpFirstOrLast : true,
            firstLabel : '最初のページ',
            lastLabel : '最後のページ',
            nextLabel: '次へ',
            prevLabel: '前へ',
            rowsPerPageLabel: '1ページあたりの行数',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          },"sort-options":{
            enabled: true,
            initialSortBy: {field: 'send_time', type: 'desc'},
          },"compactMode":""}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" データがありません。 ")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }