<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu />
      </div>
      <div class="column">
        <div>
          <h1 class="is-size-4 has-text-weight-bold py-1">QRコードの生成</h1>
        </div>
        <hr>
        <div>
          <h2 class="is-size-5 has-text-weight-bold">事業所ページのQRコード</h2>
          <div v-if="business_doc.business_id">
            <h5>{{ business_doc.business_name + ' ' + business_doc.branch_name}}</h5>
            <a :href="'https://jp.ilb.jobs/' + business_doc.business_id + '/'" target="_blank">
              https://jp.ilb.jobs/{{ business_doc.business_id }}/
            </a>
            <p class="my-1">
              <vue-qrcode id='business-qr-img' v-if="targetBusinessQrText" :value="targetBusinessQrText" :options="option" tag="img"></vue-qrcode>
            </p>
            <p>
              <b-button class="btn col s12 #e91e63 pink" type="is-success" @click="downloadImage('business')" >
                ダウンロード
              </b-button>
            </p>
          </div>
          <div v-else>
            <p class="has-text-danger my-1">
              ビジネスデータが未設定です
            </p>
            <p>
              <img src="@/assets/no_data_qr.jpg" width="300" style="opacity: 0.5;">
            </p>
            <p>
              <b-button class="btn col s12 #e91e63 pink" type="is-success" @click="downloadImage('business')" disabled>
                ダウンロード
              </b-button>
            </p>
          </div>
        </div>
        <hr>
        <div>
          <h2 class="is-size-5 has-text-weight-bold">求人ページのQRコード</h2>
          <div v-if="Object.keys(page_docs).length">
            <p class="">
              下記より作成したいページを選択してください。
            </p>
            <div class="select">
              <b-select id="employment_type" v-model="selected_page_id" placeholder="選択してください">
                <option v-for="(value, index) in jobTypeEmploymentTypeList" :key="index" :value="value.page_id">{{ value.display_name }}</option>
              </b-select>
            </div>
            <p v-if="selected_page_id">
              <a :href="'https://jp.ilb.jobs/page/' + selected_page_id + '/'" target="_blank">
                https://jp.ilb.jobs/page/{{ selected_page_id }}/
              </a>
            </p>
            <div v-if="selected_page_id">
              <p class="my-1">
                <vue-qrcode id='page-qr-img' v-if="targetPageQrText" :value="targetPageQrText" :options="option" tag="img"></vue-qrcode>
              </p>
              <p>
                <b-button class="btn col s12 #e91e63 pink" type="is-success" @click="downloadImage('page')" >
                  ダウンロード
                </b-button>
              </p>
            </div>
          </div>
          <div class="pb-3" v-else>
            <p class="has-text-danger my-1">
              求人データが未設定です
            </p>
            <div>
              <p>
                <img src="@/assets/no_data_qr.jpg" width="300" style="opacity: 0.5;">
              </p>
              <p>
                <b-button class="btn col s12 #e91e63 pink" type="is-success" @click="downloadImage('page')" disabled>
                  ダウンロード
                </b-button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from 'axios'
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'

export default {
  components:{
    Menu,
    VueQrcode
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getPageDocsData() {
      return this.$store.getters.page_docs;
    },
    targetPageQrText() {
      return "https://jp.ilb.jobs/page/" + this.page.page_id
    },
    targetBusinessQrText() {
      return "https://jp.ilb.jobs/" + this.business_doc.business_id
    }
  },
  data() {
    return {
      option: {
          errorCorrectionLevel: "M",
          maskPattern: 0,
          margin: 5,
          scale: 2,
          width: 300,
          color: {
          dark: "#000000FF",
          light: "#FFFFFFFF"
          }
      },
      business_doc: this.$store.getters.business_data || {},
      page_docs: this.$store.getters.page_docs || {},
      page :{},
      selected_page_id : null,
      jobTypeEmploymentTypeList : [
        {
          page_id : '',
          display_name :''
        }
      ],
    }
  },

  methods: {
    async downloadImage(type) {
      // 参考：https://r17n.page/2020/01/12/js-download-image-to-local/
      const src = document.querySelector("#" + type + "-qr-img").getAttribute("src");

      // ちゃんと例外処理したほうが良いけど省略
      const response = await axios.get(src, { responseType: "blob" });
      this.downloadImageToLocal(response ,type);
    },
    downloadImageToLocal(response, type) {
      let dlLink = document.createElement("a");

      const dataUrl = URL.createObjectURL(response.data);
      dlLink.href = dataUrl;

      const fileName = 'QRコード：'
        + (type === 'business'
          ? this.business_doc.business_name + (this.business_doc.branch_name ? ('_' + this.business_doc.branch_name) : '')
          : this.page.job_type + '_' + this.employmentTypeJa(this.page.employment_type));
      dlLink.download = fileName;

      document.body.insertAdjacentElement("beforeEnd", dlLink);
      dlLink.click();
      dlLink.remove();

      setTimeout(function() {
        window.URL.revokeObjectURL(dataUrl);
      }, 1000);
    },
    setJobTypeEmploymentTypeList () {
      this.jobTypeEmploymentTypeList = Object.keys(this.page_docs).map((page_id) => {
        return {
          page_id : page_id,
          display_name : this.page_docs[page_id].job_type + '：' + this.employmentTypeJa(this.page_docs[page_id].employment_type)
        }
      })
    }
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
    // page_docsの監視
    getPageDocsData(newValue) {
      this.page_docs = newValue;
      // listにセット
      this.setJobTypeEmploymentTypeList()
    },
    selected_page_id (newValue) {
      this.page = this.page_docs[newValue]
    }
  },
}
</script>

<style scoped>
</style>
