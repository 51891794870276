<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu />
    </div>

    <div class="column">
      <h1 class="is-size-4 has-text-weight-bold">よくある質問</h1>
      <p class="">本サービスについて、管理画面の操作方法、ご利用料金のお支払いなどについてのFAQ（よくある質問）をご確認いただけます。</p>

      <hr>
      <p v-for="(faq, index) in faq_list" :key="index">
        <span class="icon-question-circle"></span>&nbsp;<a :href="faq.url" target="_blanc">{{ faq.title }}</a><br>
      </p>
      <hr>
      <p>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1MjC6wmj52evEfMYutzmEZcSCUOPy9bBkS3gRXOxQ_ig/edit" target="_blanc">テキスト・画像の追加、修正方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/19NfbZ2qdNwzNcDxFNVobnkdcz5k-2eJp4H4LgzudjgQ/edit" target="_blanc">QRコードの作成方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1qn-USLkP1q3lcDCXFM2w1aciSXe0pC3ltqVUoeXpqOY/edit" target="_blanc">YouTube動画のIDを確認する方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1R1mBuu_HQR2bl1qYJVDm-H8pvTr27Ty47DRjStKxMNo/edit" target="_blanc">事業所用のチラシの作成方法と活用方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/10Ghj1YYF7bCCuNSJudNhpStL-br25H_iRu-OuQ4Vavk/edit" target="_blanc">求人用チラシの作成方法と活用方法</a><br>
      </p>
    </div>
  </div>
</section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'

export default {
  components: {
    Menu,
  },
  data() {
    return {
      faq_list: {},
    }
  },
  computed: {
    // faqデータを表示順に並び替え
    getAdminData() {
      return this.$store.getters.admin_data;
    },
  },
  methods: {
  },
  watch: {
    getAdminData(newValue) {
      // 変更の場合には既存データ
      if(newValue === undefined) return
      // admin_data.faq.***.display_orderで並び替え、display_orderがない場合は無視する
      this.faq_list = Object.keys(newValue.faq).map(key => {
        return newValue.faq[key];
      }).filter(faq => faq.display_order).sort((a, b) => a.display_order - b.display_order);
    },

  },
}
</script>

<style>
</style>
