<template>
  <section class="section container" v-if="business_doc">
    <div class="columns">
      <div class="column is-3">
        <Menu />
      </div>
      <div class="column">
        <div>
          <h1 class="is-size-4 has-text-weight-bold py-1">転送用URLの設定</h1>
        </div>
        <div>
          <p>
            事業所のページURLのhttps://jp.ilb.jobs/<span class="has-text-danger">●●●●●</span>の部分に、会社名や店舗名などご希望の文字列を設定できます。
          </p>
          <b-field
              :type="errors.has('transfer_id') ? 'is-danger': ''"
              :message="errors.has('transfer_id') ? customMessageTransferId: ''"
              label="https://jp.ilb.jobs/" horizontal>
              <b-input
                name="transfer_id"
                id="transfer_id"
                v-model="input_transfer_id"
                @input="duplicationCheck()"
                v-validate.immediate="{ regex:regex_transfer_id }"
                placeholder=""
                data-vv-as="転送用ID"
                :disabled="business_doc.plan !== 'standard'"
              ></b-input>
          </b-field>
          <div class="pb-2">
            <p>※半角英数字とハイフン（-）が使用可能です。</p>
            <p>無料プランに変更した場合、転送用URLは削除となります。</p>
          </div>
          <div v-if="business_doc.plan === 'standard'">
            <div v-if="duplicateID">
              <b-button class="button is-info is-fullwidth" disabled>転送用URLを設定する</b-button>
              <p class="has-text-danger mt-1">すでに使用されているURLのため設定できません</p>
            </div>
            <div v-else>
              <b-button class="button is-info is-fullwidth" @click="saveTransferID()">転送用URLを設定する</b-button>
            </div>
          </div>
          <div v-else>
            <p>※転送用URLは有料プランの場合に設定が可能です。	</p>
            <b-button
              class="button is-info is-fullwidth"
              @click='$router.push( "/plan_change" ).catch(() => {})'
              >
              有料プランに変更する
              </b-button>
            <p class="help">
              <a href="https://r.ilb.net/ip-plan-ja" target="_blank">
                有料プランと無料プランの違い<b-icon
                  icon="launch"
                  size="is-small"
                ></b-icon>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import Config from '../config.js'

export default {
  components:{
    Menu,
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
  },
  data() {
    return {
      business_doc: this.$store.getters.business_data,
      duplicateID: 0,
      regex_transfer_id : /^[0-9a-zA-Z-]*$/,
      customMessageTransferId: '半角英数字、記号「ｰ」で入力してください',
      input_transfer_id: ''
    }
  },

  methods: {
    /**
     * オリジナルIDの重複チェック
     */
     async duplicationCheck () {
      if (!this.input_transfer_id) {
        return
      }
      const businessDataSnap = await Config.BUSINESS_COLLECTION
        .where('business_id', '!=', this.business_doc.business_id)
        .where('transfer_id', '==', this.input_transfer_id)
        .get()

        // 件数カウント
        this.duplicateID = businessDataSnap.size
    },
    /**
     * オリジナルIDだけ保存（ボタンが押せるときのみ保存）
     */
    async saveTransferID() {
      // 念の為保存前に重複チェック
      if (this.duplicateID) {
        this.$swal('error', 'すでに使用されているIDのため設定できません', 'error')
        return
      }

      try {
        // business_dataデータを変更する
        // input_transfer_idの値をtransfer_idにセット
        this.business_doc.transfer_id = this.input_transfer_id
        // 更新のdispatch
        await this.$store.dispatch('updateBusinessData', {
          doc_id : this.business_doc.business_id,
          data : {
            transfer_id : this.business_doc.transfer_id.trim()
          }
        })

      } catch(e) {
          this.$swal('error', '保存エラー：' + e, 'error')
          return
      }

      await this.$swal.fire({
        html: '転送用URLを設定しました。'
          + '<BR><BR>'
          + this.business_doc.business_name
          + (this.business_doc.branch_name ? ` ` + this.business_doc.branch_name : '')
          + '<br>'
          + '転送用URL'
          + '<BR>'
          + '<a href="' + Config.DESTINATION_URL + '/' + this.business_doc.transfer_id + '" target="_BLANK">'
          + Config.DESTINATION_URL + '/'
          + (this.business_doc.transfer_id || this.business_doc.business_id)
          + '</a>',
        icon: 'success',
        confirmButtonText: '閉じる',
        allowOutsideClick: false
      })
    }
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
    // business_doc.transfer_idの監視
    'business_doc.transfer_id': {
      handler: function (newValue) {
        this.input_transfer_id = newValue
        //  buttonのdisabledを解除
        this.duplicateID = 0
      },
      deep: true
    }
  },
}
</script>

<style scoped>
</style>
