<template>
<section class="section container">

  <div class="has-text-centered pb-5">
    <p class="subtitle is-size-4 is-size-5-mobile has-text-weight-bold pb-1">＼ 初期費用、成果報酬０円 ／</p>
    <h2 class="title is-size-1 is-size-3-mobile has-text-danger has-text-weight-bold">
      <span class="marker">求人し放題のウェブページを無料作成</span>
    </h2>
  </div>

  <!-- オファー -->
  <div class="columns is-multiline is-centered">
    <div class="column is-8-desktop">

      <article class="message is-danger">
        <div class="message-header">
          <p class="subtitle is-size-4 is-size-6-mobile has-text-white has-text-weight-bold">
            スマホで作れる求人サイト
          </p>
        </div>

        <div class="message-body">
          <div class="columns">

            <div class="column is-half has-text-centered">
              <figure class="block">
                <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbjobs-jp/signup/offer_image.jpg" loading="lazy" alt="ローカルリクルートマネージャー">
              </figure>
            </div>

            <div class="column">

              <div class="block has-text-dark">
                  <p>
                    募集したい求人内容をわかりやすく「1ページ」にまとめたウェブページを作成できます。同時に求人用のチラシも作成できます。<br>
                    <br>
                    募集している求人一覧を表示できる事業所ページも作成できるので御社専用の求人サイトとしてご活用いただけます。
                  </p>
                  <!--
                  <p class="pt-5 pb-1 has-text-weight-bold">
                    このような方は、今すぐお申込ください
                  </p>
                  <p>
                    <span class="icon-check-square"></span>&nbsp;集客できるウェブページを作りたい<br>
                    <span class="icon-check-square"></span>&nbsp;ポスティングやＤＭで使えるチラシを作りたい<br>
                    <span class="icon-check-square"></span>&nbsp;集客だけでなく客単価も上げたい<br>
                  </p>
                  <p class="pt-5">
                    １つでも該当する場合は、気軽にページの代理作成をご依頼ください。<br>
                    ※ページが不要になった場合は、いつでも削除可能です。
                  </p>
                  -->
              </div>

              <a href="#contact" class="button is-fullwidth is-medium is-danger has-text-weight-bold">今すぐ無料で作成する</a>
            </div>

          </div>
        </div>
      </article>

    </div>
  </div>


  <br><br>

  <div class="has-text-centered block">
    <h2 class="title is-size-3 is-size-4-mobile">
      <span class="marker">３つの特長（選ばれる理由）</span>
    </h2>
  </div>


  <div class="columns is-multiline is-centered">

    <div class="column box is-8-desktop">
      <p class="title is-size-4 is-size-5-mobile">
        <span class="has-text-danger">最短10分で求人掲載が完了</span>
      </p>

      <div class="columns">
        <div class="column is-one-third">
          <figure class="block">
            <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbjobs-jp/signup/feature1_image.jpg" loading="lazy" alt="最短10分で求人掲載が完了">
          </figure>
        </div>
        <div class="column">
          <p>
            決められた項目を入力するだけで、求人ページの掲載が完了します。スマホからでも簡単に入力できるので、専門知識がなくてもその日のうちに採用を始められます。
          </p>
        </div>
      </div>
    </div>


    <div class="column box is-8-desktop">
      <p class="title is-size-4 is-size-5-mobile">
        <span class="has-text-danger">求人用チラシも作成できる</span>
      </p>

      <div class="columns">
        <div class="column is-one-third">
          <figure class="block">
            <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbjobs-jp/signup/feature2_image.jpg" loading="lazy" alt="求人用チラシも作成できる">
          </figure>
        </div>
        <div class="column">
          <p>
            店内での掲示だけでなく、ポスティングなどでお使いいただける「求人用チラシ」が自動で作成できます。
          </p>
        </div>
      </div>
    </div>


    <div class="column box is-8-desktop">
      <p class="title is-size-4 is-size-5-mobile">
        <span class="has-text-danger">初期費用や成果報酬は無料</span>
      </p>

      <div class="columns">
        <div class="column is-one-third">
          <figure class="block">
            <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/feature3_image.jpg" loading="lazy" alt="初期費用や成果報酬は無料">
          </figure>
        </div>
        <div class="column">
          <p>
            求人ページの作成時の初期費用は無料で採用時の成果報酬もありません。月額費用11,000円のみで求人件数の制限もなく利用できます。
          </p>
        </div>
      </div>
    </div>

  </div>

 <br><br>

  <div class="has-text-centered block">
    <h2 class="title is-size-3 is-size-4-mobile">
      <span class="marker">お客様の声と制作事例</span>
    </h2>
  </div>

  <div class="columns is-multiline is-centered block">

    <div class="column box is-8-desktop">
      <p class="title is-size-4 is-size-5-mobile has-text-danger is-marginless">
        簡単に求人ページが作れる！チラシも便利！！
      </p>
      <br>
      <div class="columns">
        <div class="column is-one-third">
          <figure class="block">
            <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/case1_image.jpg" loading="lazy" alt="VegeFru Kitchen（福岡県福岡市）">
          </figure>
        </div>
        <div class="column">
          <p class="block has-text-weight-bold">
            VegeFru Kitchen（福岡県福岡市）<br>
            店長：青木様
          </p>
          <p class="block">
            求人用ページ、簡単に作成できました。印刷したチラシは、店内での配布やポスティングに使っています。
          </p>

        </div>
      </div>

<!--
      <div class="columns">
        <div class="column is-half">
          <figure class="block">
            <img class="pb-1" style="display: block; margin: auto;" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/page_mobile.jpg" loading="lazy" alt="インデックスページのイメージ画像">
          </figure>
          <a href="https://jp.ilb.net/000000002" target="_blank" class="button is-danger is-outlined is-fullwidth">インデックスページを見る</a>
        </div>
        <div class="column">
          <figure class="block">
            <img class="pb-1" style="display: block; margin: auto;" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/flyer_page.jpg" loading="lazy" alt="宣伝用チラシのイメージ画像">
          </figure>
          <a href="https://drive.google.com/file/d/1t-iKP0olln8NivLuWC7N4UaWp27oqlbT/view" target="_blank" class="button is-danger is-outlined is-fullwidth mt-2">宣伝用チラシを見る</a>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <figure class="block">
            <img class="pb-1" style="display: block; margin: auto;" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/flyer_line.jpg" loading="lazy" alt="LINE友だち募集用チラシのイメージ画像">
          </figure>
          <a href="https://drive.google.com/file/d/1NzUcXo4sFUF8MkvyFTAXNzTaadqceIkA/view" target="_blank" class="button is-danger is-outlined is-fullwidth mt-2">LINE友だち募集用チラシを見る</a>
        </div>
        <div class="column">
          <figure class="block">
            <img class="pb-1" style="display: block; margin: auto;" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/flyer_gbp.jpg" loading="lazy" alt="Googleクチコミ募集用チラシのイメージ画像">
          </figure>
          <a href="https://drive.google.com/file/d/1_ULLOuMKBCW_817ucpLSsUiyXE2YdZx3/view" target="_blank" class="button is-danger is-outlined is-fullwidth mt-2">Googleクチコミ募集用チラシ見る</a>
        </div>
      </div>
-->

    </div>

  </div>


<br><br>


  <div class="has-text-centered block">
    <h2 class="title is-size-3 is-size-4-mobile">
        <span class="marker">
          よくある質問
        </span>
    </h2>
  </div>

  <div class="columns is-multiline is-centered ">
    <div class="column box is-8-desktop">

        <p class="is-size-4 is-size-5-mobile has-text-danger has-text-weight-bold pb-3">
          専門知識がないですが、ページ作成できますか？
        </p>

        <p class="">
          はい。専用の管理画面より求人情報を入力するだけなので、簡単に作成できます。<br>
          <br>
        </p>

        <hr>

        <p class="is-size-4 is-size-5-mobile has-text-danger has-text-weight-bold pb-3">
          月額のサービス利用料以外に、何か費用は発生しますか？
        </p>

        <p class="">
          求人用のウェブページやチラシにおいて、追加の費用は一切かかりません。<br>
          <br>
        </p>

        <hr>

        <p class="is-size-4 is-size-5-mobile has-text-danger has-text-weight-bold pb-3">
          復数のウェブページを作成できますか？
        </p>
        <p class="">
          はい。作成可能です。<br>
          同一の事業所であれば、制限なく求人ページを作成（追加）いただけます。
        </p>


    </div>
  </div>

  <br><br>

    <div class="has-text-centered block" id="contact">
      <h2 class="title is-3 is-size-4-mobile">
        <span class="marker">サービス利用のお申込（無料）</span>
      </h2>
    </div>


    <div class="columns is-centered is-multiline">
      <div class="column notification is-8-desktop">

        <p class="block">
          サービス利用は無料でお申込いただけます。<br>
          御社専用の管理画面にアクセスし、求人ページを作成してください。<br>
          ※機能制限なくサービスをご利用いただくためには、利用金額の決済が必要となります。
        </p>
        <b-field
          :type="errors.has('email') ? 'is-danger': ''"
          :message="errors.has('email') ? errors.first('email') : ''">
          <template slot="label">
            <span class="tag is-danger">必須</span>&nbsp;メールアドレス<br>
          </template>
          <div class="control has-icons-left">
            <b-input
              name="email"
              v-model="contactForm.email"
              v-validate="{ required:true, regex: /^([a-z0-9_\-\.\+]+)@([a-z0-9_\-\.]+\.[a-z0-9]{2,})$/ }"
              data-vv-as="メールアドレス"
              placeholder="例）sample@gmail.com">
            </b-input>
            <span class="icon is-small is-left">
              <span class="icon-envelope"></span>
            </span>

          </div>
        </b-field>

        <b-field
          :type="errors.has('password') ? 'is-danger': ''"
          :message="errors.has('password') ? errors.first('password') : ''">
          <template slot="label">
            <span class="tag is-danger">必須</span>&nbsp;パスワード<br>
          </template>
          <div class="control has-icons-left">
            <b-input
              name="password"
              v-model="contactForm.password"
              v-validate="{ required:true, regex: /^(?=.*\d|.*[A-Za-z])[A-Za-z\d]{6,}$/ }"
              data-vv-as="パスワード"
              placeholder="例）abc123">
            </b-input>
            <span class="icon is-small is-left">
              <span class="icon-envelope"></span>
            </span>

          </div>
        </b-field>

        <input type ="hidden" v-model="referralData.referral_code">

        <p class="py-3 has-text-centered"><a href="https://r.ilb.net/tos-ja" target="_blank" rel="noopener">利用規約</a>&nbsp;に同意のうえ、お申込ください。</p>

        <span>
          <div v-if="displayButtons">
            <div class="control">
              <button
                class="button is-info is-large is-fullwidth has-text-weight-bold"
                id="saveForm"
                :disabled="errors.any() || !isFormCompleted"
                @click="signup"
              >
                無料会員登録
              </button>
            </div>
          </div>
          <div v-else>
            <loading-for-submit />
          </div>
        </span>
      </div>
    </div>
    <div>
      <!--
      <p class="has-text-centered">-------------または、SNSアカウントで登録-------------</p>
      -->
    </div>
    <div>
      <div id="firebaseui-auth-container"></div>
      <div id="loader">Now Loading...</div>
    </div>
    <br>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
    />


    <div class="columns is-centered is-multiline" id="phone">
      <div class="column box is-8-desktop">
          <p class="title is-4 is-size-5-mobile">
              <span class="marker">電話でのお問合せ</span>
          </p>

          <p class="">
            <a href="tel:050-3311-0925" class="is-size-2 is-size-3-mobile has-text-danger has-text-weight-bold is-hidden-mobile">
              <span class="icon-phone-alt"></span>&nbsp;050-3311-0925
            </a>
            <a href="tel:050-3311-0925" class="button is-medium is-danger is-fullwidth has-text-weight-bold is-hidden-desktop">
              <span class="icon-phone"></span>&nbsp;050-3311-0925
            </a>
          </p>
            <p class="pt-3 pb-1">
              <span class="icon-clock"></span>&nbsp;営業時間&nbsp;9:00 - 17:00
            </p>


            <p class="">
              <span class="icon-times-circle"></span>&nbsp;定休日：土曜、日曜、祝日<br>
            </p>
      </div>
    </div>


    <br>

    <div class="columns is-centered is-multiline" id="line">
      <div class="column box is-8-desktop">
        <p class="title is-4 is-size-5-mobile">
          <span class="marker">LINEでのお問合せ</span>
        </p>

        <figure class="image is-pulled-right is-128x128 is-hidden-mobile">
          <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://r.ilb.net/contact-line-ja" loading="lazy" alt="ILB LINE">
        </figure>

        <p class="pb-3">
            LINE公式アカウントから、お問合せいただけます。<br>
            <span class="is-hidden-desktop">下のボタンより</span><span class="is-hidden-mobile">右のQRコードより</span>LINEの友だちに追加し、メッセージを送信してください。<br>
            <span class="is-hidden-mobile">
              <br>
              LINE公式アカウントのID ： @ilbinc
            </span>
        </p>
        <p>
          <a href="https://r.ilb.net/contact-line-ja" class="button is-success is-medium  has-text-weight-bold is-fullwidth is-hidden-desktop"><span class="icon-line"></span>&nbsp;LINEで問合せる</a>
        </p>
      </div>
    </div>

  <br><br>

  <div class="has-text-centered block">
    <h2 class="title is-size-3 is-size-4-mobile">
      <span class="marker">会社概要</span>
    </h2>
  </div>

  <div class="columns is-multiline is-centered">
    <div class="column box is-8-desktop">

      <div class="columns">
        <div class="column is-one-third">
          <figure class="block">
            <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/signup/business_image.jpg" loading="lazy" alt="株式会社ILB">
          </figure>
        </div>


        <div class="column">

            <p class="block">
              株式会社ILBは、ローカルビジネスに特化したネット集客支援の専門会社です。2007年の創業以来、ウェブページ制作や広告運用代行など50,000社以上の集客支援実績があります。
            </p>

            <table class="table is-striped is-hoverable is-fullwidth block">
                <tbody>
                    <tr>
                        <th>事業所名</th>
                        <td>株式会社ILB</td>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <td>〒810-0075<br>
                            福岡県福岡市中央区港1-8-4-1F</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td><a href="tel:050-3311-0925">050-3311-0925</a></td>
                    </tr>
                    <tr>
                        <th>定休日</th>
                        <td>土曜、日曜、祝日</td>
                    </tr>
                    <tr>
                        <th>受付時間</th>
                        <td>
                            09:00 - 17:00<br>
                        </td>
                    </tr>

                    <tr>
                      <th>公式サイト</th>
                      <td>
                        <a href="https://www.ilb.net/" target="_blank" rel="noopener">https://www.ilb.net/</a>
                      </td>
                    </tr>

                </tbody>
            </table>

        </div>
      </div>

    </div>
  </div>


    <br>

</section>
</template>

<script>
import LoadingForSubmit from '@/components/LoadingForSubmit'
import Config from '../config.js'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebaseui from 'firebaseui-ja'
import 'firebaseui-ja/dist/firebaseui.css'
// import firebase from 'firebase/app'


export default {
  components: {
    LoadingForSubmit,
    Loading
  },
  created () {
    // URLのパラメータを取得
    let urlParam = location.search.substring(1)

    // URLにパラメータが存在する場合
    if(urlParam) {
      // 「&」が含まれている場合は「&」で分割
      let param = urlParam.split('&')

      // パラメータを格納する用の配列を用意
      let paramArray = [];

      // 用意した配列にパラメータを格納
      for (let i = 0; i < param.length; i++) {
        let paramItem = param[i].split('=');
        paramArray[paramItem[0]] = paramItem[1];
      }

      // 紹介コードがあった場合のみ
      if (paramArray.code) {
        this.referralData.referral_code =  paramArray.code
      }
    }
  },
  data: () => ({
    contactForm: {
      email: '',
      password: '',
    },
    referralData: {
      account_name: '',
      account_number: '',
      account_type: '',
      business_name:'',
      phone:'',
      bank_code: '',
      branch_code: '',
      referral_code: '',
      deleted: false,
      declined: true
    },
    displayButtons: true,
    key: 0,
    events: {},
    isLoading: false,
    fullPage: true
  }),
  computed: {
    isFormCompleted: function() {
      if (
        !this.contactForm.email ||
        !this.contactForm.password
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    signup: async function () {
      // 初期パスワードは電話番号
      const isValid = await this.$validator.validate()
        if(!isValid) return

      // ローディング
      this.isLoading = true

      // ボタンの非アクティブ
      this.displayButtons = false

      // ユーザー作成
      try {
        await Config.FIREBASE_AUTH.createUserWithEmailAndPassword(this.contactForm.email, this.contactForm.password)

        // vuexにUIDを保存
        await this.$store.commit('user', Config.FIREBASE_AUTH.currentUser)
        } catch(e) {
          switch (e.message) {
            case 'The email address is already in use by another account.':
                this.$swal('warning', "このemailアドレスはすでに利用中です。", 'warning')
                break;

            case 'Password should be at least 6 characters':
                this.$swal('warning', "パスワードは最低6文字以上を入力してください", 'warning')
                break;

            case 'The email address is badly formatted.':
                this.$swal('warning', "メールアドレスの形式が違います", 'warning')
                break;

            case 'The email is already in use as another user\'s initial email.':
                this.$swal('warning', "このemailアドレスはすでに利用中です。", 'warning')
                break;

            default:
                break;
          }

          // 送信ボタンアクティブに戻す
          this.displayButtons = true
          this.contactForm.loading = false
          // ローディング
          this.isLoading = false
          return
      }

      const createReferralDataNewCustomer = Config.FIREBASE_FUNCTIONS.httpsCallable("createReferralDataNewCustomer");

      try {
        this.referralData.uid = this.$store.state.user.uid;
        let result = await createReferralDataNewCustomer(this.referralData);
        if (result.data.status) {
          // vuexにreferralDataを保存
          this.$store.commit('referral_data', this.referralData)
          // 送信ボタンをアクティブに
          this.displayButtons = true
          // ローディング
          this.isLoading = false
          // 画面閉じる時のjsアラートをOFFに
          this.removeListener(this.key)
          // completeページへ遷移
          this.$router.push('/business/new')
        } else {
          // 送信ボタンアクティブに戻す
          this.$swal('error', "登録に失敗いたしました。事務局に問い合わせください。", 'error')
          this.displayButtons = true
          this.contactForm.loading = false
          // ローディング
          this.isLoading = false
        }
        return
      } catch(e) {
        // 送信ボタンアクティブに戻す
        console.debug('【エラー】', e)
        this.$swal('error', "保存に失敗いたしました。事務局に問い合わせください。", 'error')
        this.displayButtons = true
        this.contactForm.loading = false
        this.isLoading = false
        return
      }
    },
    // イベント登録用
    addListener: function(target, type, listener, capture) {
        // イベントリスナーに登録
        target.addEventListener(type, listener, capture)
        // 削除時に利用できるよう保持
        this.events[this.key] = {
            target: target,
            type: type,
            listener: listener,
            capture: capture
        }
        return this.key++
    },
    // イベント削除用
    removeListener: function(key) {
        if(key in this.events) {
            let e = this.events[key];
            e.target.removeEventListener(e.type, e.listener, e.capture);
        }
    },
    sleep: time => new Promise(resolve => setTimeout(resolve, time))
  },
  mounted () {
    // イベント登録（好きなタイミングでイベント削除できるようにkeyを取得）
    this.key = this.addListener(window, 'beforeunload', (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault()
            // Chrome requires returnValue to be set.
            event.returnValue = 'このページを離れようとしています'
    }, false)

    // firebase authentication
    const vm = this
    let ui = firebaseui.auth.AuthUI.getInstance()
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(Config.FIREBASE_AUTH)
    }
    ui.start('#firebaseui-auth-container', {
      callbacks: {
        uiShown: function () {
          document.getElementById('loader').style.display = 'none'
        },
        signInSuccessWithAuthResult: function (authResult) {
          const isNewUser = authResult.additionalUserInfo.isNewUser

          // redirectUrl

          // SNSログイン＆で登録済みであればスキップ
          if (!isNewUser) {
            return true
          }
        },
        signInFailure: function (error) {
          // console.log("signInFailure", error)
          alert(error.message)
          vm.$router.go()
        }
      },
      autoUpgradeAnonymousUsers: true,
      signInFlow: 'redirect',
      signInSuccessUrl: '/',
      signInOptions: [
        {
          // provider : firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // fullLabel: 'Login with Microsoft',
          // loginHintKey: 'login_hint',
        }
      ],
    })
  }
}
</script>

<style scoped>
.marker {
  background: linear-gradient(transparent 60%, #ffff7f 0%);
}

html{
  scroll-behavior:smooth;
}
.message-header p {
  margin: auto;
  text-align: center;
}

.message.is-danger .message-body {
  background-color:#ffffff;
  border-color:#f14668;
  border-width: 3px;
  color: #4a4a4a;
}

.message.is-success .message-body{
  background-color:#ffffff;
  border-color:#48c774;
  border-width: 3px;
  color: #4a4a4a;
}

@media (min-width: 751px) {
a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: #212529;
    }
}
</style>
