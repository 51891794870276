<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu />
      </div>
      <div class="column">
        <div class="pb-3">
          <h1 class="is-size-4 has-text-weight-bold py-1">事業所情報の{{ business_doc && business_doc.business_id ? `入力・修正` : `新規作成`}}</h1>
          <p v-if="!business_doc || !business_doc.business_id">
            求人を作成する事業所（店舗・会社）情報を入力してください。
          </p>
          <p v-if="disabled" class="has-text-danger">
            必須項目が未入力です。
          </p>
          <p v-else>
            <span v-if="business_doc && business_doc.business_id">
              {{ business_doc.business_name }} {{ business_doc.branch_name }}
              <br>
              {{ business_doc.transfer_id ? destination_url + `/` + business_doc.transfer_id : destination_url + `/` + business_doc.business_id }}
            </span>
          </p>
        </div>

        <BusinessInfo
          @imgUpdate="imgUpdate"
          ref="businessimage"
        />
        <BusinessReferenceInfo
          @imgUpdate="imgUpdate"
          ref="businessinfoimage"
        />
        <RelatedSites />
        <FrequentlyAskedQuestions />
        <div class="bottom-menu pt-1 pb-2">
            <div class="mt-1">
              <b-button
                :disabled="disabled"
                class="button is-info"
                @click="save_submit()"
                size="is-large"
                expanded
              >
              <span>
                保存する
              </span>
            </b-button>
          </div>
        </div>
        <p v-if="disabled" class="has-text-danger">
          必須項目が未入力です。
        </p>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import moment from 'moment'
import Config from '../config.js'
// 画像圧縮用のライブラリ
import Compressor from 'compressorjs';
// 左メニュー
import Menu from '@/components/Menu.vue'
// 基本情報
import BusinessInfo from '@/components/business/BusinessInfo.vue'
// 参考情報
import BusinessReferenceInfo from '@/components/business/BusinessReferenceInfo.vue'
// 関連情報
import RelatedSites from '@/components/business/RelatedSites.vue'
// FAQ
import FrequentlyAskedQuestions from '@/components/business/FrequentlyAskedQuestions.vue'

export default {
  components: {
    Menu,
    BusinessInfo,
    BusinessReferenceInfo,
    RelatedSites,
    FrequentlyAskedQuestions
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if(from.path === '/signup') {
        vm.$swal.fire({
          title: '申込み完了',
          html:'ローカルリクルートマネージャー'
           + '<br>'
           + 'サービスの利用申込みが完了しました。'
           + '<br><br>'
           + '管理画面から、事業所情報を入力して求人ページを作成しましょう。',
          icon: 'success',
          confirmButtonText: '事業所情報の入力画面に移動する',
          allowOutsideClick: false
        })
      }
    })
  },
  computed : {
    business_title () {
      return this.$store.getters.business_title
    },
    //  storeのstateの変化時
    getBusinessData() {
      return this.$store.getters.business_data && Object.keys(this.$store.getters.business_data).length
        ? this.$store.getters.business_data
        : this.$store.getters.admin_data.default_business_data
    },
    // ボタンのアクティブ
    disabled : {
      get () {
        // 必須項目が全て入力されていたらボタンをアクティブにする
        return !this.pageUrlCheck()
      },
      set (value) {
        return value
      }
    },
    destination_url () {
      return Config.DESTINATION_URL
    },
  },
  watch : {
    // storeのstateが変更されたcomputedを検知してthisを書き換える
    getBusinessData(newValue) {
      // 変更の場合には既存データ
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
  },
  methods: {
    pageUrlCheck () {
      if (this.business_doc
        && this.business_doc['category_group']
        && this.business_doc['category']
        && this.business_doc['business_name']
        && this.business_doc['phone']
        && this.business_doc['email']
        && this.business_doc['zip_code']
        && this.business_doc['region']
        && this.business_doc['city']
        && this.business_doc['street_address']
        )
      {
        return true
      }
      return false
    },
    /**
     * 画像アップロード処理（保存ボタン押した時に発火）
     * 各子コンポーネント上での画像アップロードデータをimageDataに入れる
     * 配列上に保存しておいて保存ボタンで一気にアップロード＋削除
     */
    imgUpdate (imageFileData) {
      // 配列の初期化
      if (this.imageData[imageFileData['imageTitle']] === undefined) {
        this.imageData[imageFileData['imageTitle']] = []
      }

      // business_image, staff_image, line_image, offer_imageはarrayNumは0
      this.imageData[imageFileData['imageTitle']][imageFileData['arrayNum'] || 0] = imageFileData
    },
    /**
     * ページ保存ボタン送信
     */
    async save_submit () {
      // ボタンを非アクティブ
      this.disabled = true
      // loading
      this.isLoading = true

      const business_id = await this.business_doc.business_id || await this.$store.dispatch('newBusinessId')

      // business_idの設定（新規の場合に事前に入れておく）
      await this.$set(this.business_doc, 'business_id', business_id)

      // 画像関連の変更がある場合には保存もしくは削除
      if(Object.keys(this.imageData).length !== 0){
        // 画像変更あり時にbusiness_docを上書き
        this.business_doc = await this.uploadAndRemove()
      }

      try {
        await this.save()
      } catch (e) {
        this.disabled = false
        // loading
        this.isLoading = false
        await this.$swal('error', '保存に失敗しました：' + e, 'error')
        return
      }
      this.disabled = false
      // loading
      this.isLoading = false

      // storeのstateを置き換える
      // await this.$store.commit('business_data', this.business_doc)

      if(this.$route.path === '/business/new') {
        await this.$store.commit('prevRoute', '/business/new')
      }

      // TOP(求人一覧へ転送)
      await this.$router.push("/").catch(() => {})

      return
    },
    /**
     * 画像アップロードor削除
     */
    async uploadAndRemove() {
      // アップロードおよび削除予定の画像他の情報
      const imageFileData = this.imageData

      let doc = this.business_doc

      // アップロード＋page_dataへのURL保存
      const uploadAndRemove = (data) => {
        // Promise を返却
        return new Promise((resolve) => {
          Object.keys(data).map(imageTitle => {
            Object.keys(data[imageTitle]).map(arrayNum => {
              const image = data[imageTitle][arrayNum]
              // 削除の場合には飛ばす
              if(!image.isDeleted) {
                doc = this.imgUpload(imageTitle, arrayNum, image)
              } else {
                // 削除対象のURLがあれば
                if (image.src) {
                  doc = this.imgRemove(imageTitle, arrayNum, image)
                }
              }
            })
          })
          resolve(doc)
        })
      }
      return await uploadAndRemove(imageFileData) // Promise が返却される
    },
    /**
     * 画像アップロード
     */
    async imgUpload(imageTitle, arrayNum, image) {
      // firebase storage
      const storage = Config.FIREBASE_STORAGE;

      // 画像のアップロード先の準備（ファイル名は新規作成）
      const mountRef = await storage
        .ref()
        .child(
          Config.STORAGE_DIR +
            "/" +
            this.business_doc['business_id'] +
            "/" +
            imageTitle +
            "_" +
            (Math.random().toString(36).slice(-8)) +
            ".jpg"
        );

      // 圧縮+jpg化+アップロード+page_dataへのURL保存
      return await this.compress(image, mountRef, arrayNum, imageTitle)
    },
    /**
     * 画像アップロード時の圧縮・保存
     */
    async compress(image, mountRef, arrayNum, imageTitle) {
      // const deleteFileUrl = image.src

      const promisfiedCompressor = ( file, q=0.92, maxWidth=1200, mimeType='image/jpeg') => {
        // Promise を返却
        return new Promise((resolve, reject) => {

          new Compressor(file, {
            quality: q,
            maxWidth:maxWidth,
            mimeType: mimeType,
            success(blob) {
              resolve(blob); // Promise を 成功終了
            },
            error(err) {
              reject(err); // Promise を 異常終了
            }
          }); // Compressor.js
        });
      }

      const file = await promisfiedCompressor(image.file) // Promise が返却される

      try {
        // 画像アップロード保存
        const snapshot = await mountRef.put(file)
        // 保存後のURL取得
        const url = await snapshot.ref.getDownloadURL()

        let docFieldData = this.business_doc[image.docFieldTitle]

        // business_image|staff_image|line_image|offer_imageの場合は別処理
        switch (imageTitle) {
          case 'business_image':
            docFieldData[arrayNum] = url
            this.business_doc[image.docFieldTitle] = docFieldData;
            break;

          default:
            // business_info
            docFieldData[arrayNum]['image'] = url
            this.business_doc[image.docFieldTitle] = docFieldData;
            break;
          }
          // // 既存ファイル削除
          // 20230803 Elastic Searchのため実ファイルは削除しない方向に
          // if(deleteFileUrl) {
          //   await this.deleteStorageFile(deleteFileUrl);
          // }
        } catch (error) {
          console.log(error)
          this.$swal("画像アップロードエラー", "エラー内容：" + error, "error");
        }

      // 画面上のcanvas削除 refsを使って子コンポーネントのreset関数呼び出し
      this.$refs[imageTitle.replace(/_/g, "").replace(/[0-9]/g, "")].reset();
      return this.business_doc
    },
    /**
     * 画像削除
     * imageTitleの種類は以下の２種類
     * business_image
     * business_info
     */
    async imgRemove(imageTitle, arrayNum, image) {
      const db = Config.BUSINESS_COLLECTION.doc(this.business_doc['business_id'])
      let doc = this.business_doc

      let docFieldData = this.business_doc[image.docFieldTitle]

      switch (imageTitle) {
        case 'business_image':
          docFieldData[arrayNum] = ""
          doc[image.docFieldTitle][arrayNum] = ''
          await db.update({ [image.docFieldTitle] : docFieldData })
          break;

        default:
          // business_info
          docFieldData[arrayNum]['image'] = ""
          doc[image.docFieldTitle][arrayNum]['image'] = ''
          await db.update({ [image.docFieldTitle] : docFieldData })
          break;
      }

      // storageからファイル削除
      // await this.deleteStorageFile (image.src);
      return this.business_doc
    },
    /**
     * 事業所データの保存
     */
    async save() {
      // 更新時間
      await this.$set(this.business_doc, 'date', moment(moment().unix(),'X').format())

      try {
        await this.$store.dispatch('updateBusinessData', {
          doc_id: this.business_doc.business_id,
          data: this.business_doc
        })
      } catch(e) {
        await this.$swal('error', '保存エラー：' + e, 'error')
        return
      }

      await this.$swal.fire({
        title: '',
        html:'<b>変更を保存しました</b>' + '<br>'
        + this.business_doc.business_name
          + (this.business_doc.branch_name ? ` ` + this.business_doc.branch_name : '')
          + '<br>'
          + '<a href="'
          + Config.DESTINATION_URL + '/' + (this.business_doc.transfer_id || this.business_doc.business_id)
          + '" target="_BLANK">'
          + Config.DESTINATION_URL + '/' + (this.business_doc.transfer_id || this.business_doc.business_id)
          + '</a>',
        icon: 'success',
        confirmButtonText: '閉じる',
        allowOutsideClick: false
      })
      return
    },
  },
  data () {
    return {
      imageData: {},
      business_doc: this.$store.getters.business_data,
      isLoading: false,
      isFullPage: true,
    }
  },
}

</script>

<style scoped>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.bottom-menu {
  position: fixed;
  bottom: 20px;
  text-align: center;
  z-index: 4;
  /* 背景色薄いグレー */
  background: rgba(255, 255, 255, 0.9);
}

/* スマホ */
@media screen and (max-width: 768px) {
  .bottom-menu {
    width: 80%;
    left: 10%;
    right: 10%;
  }
}

/* PC */
@media screen and (min-width: 769px) {
  .bottom-menu {
    width: 60%;
    left: 30%;
  }
}

</style>
