<template>
  <!-- storeからの反映が少し遅れるのでifで待つ -->
  <section v-if="business_doc.business_info">
    <div>
      <h2 class="is-size-4 has-text-weight-bold pb-0">参考情報</h2>
    </div>
    <hr class="py-0 my-1">
    <div class="mb-5">
      <p v-html="business_title.business_info_description"></p>
    </div>

    <div v-if="updateFlg">
      <div class="content" v-for="key of business_doc.business_info.length" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
            <template #trigger>
              <b-icon icon="dots-vertical"></b-icon>
            </template>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('business_info', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('business_info',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('business_info',(key-1),'down')" :disabled="(key-1) === (business_doc.business_info.length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMoveBusiness('business_info',(key-1),'down', true)" :disabled="(key-1) === (business_doc.business_info.length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>

        <b-collapse
          aria-id="contentIdForA11y2"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3"
              role="button"
              aria-controls="contentIdForA11y2">
              <strong v-if="business_doc.business_info[key-1]['title']">{{ business_doc.business_info[key-1]['title']}}</strong>
              <strong v-else>参考情報{{ key }}のタイトルを入力してください</strong>
            </div>
          </template>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'business_info_title_' + key">参考情報{{ key }}のタイトル</label>
            <b-input
              :id="'business_info_title_' + key"
              v-model="business_doc.business_info[key-1]['title']"
              maxlength="30"
              @blur="trim('business_info', key-1, 'title')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'business_info_detail_' + key">参考情報{{ key }}の詳細 </label>
            <b-input
              :id="'business_info_detail_' + key"
              v-model="business_doc.business_info[key-1]['detail']"
              type="textarea"
              maxlength="100"
              @blur="trim('business_info', key-1, 'detail')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold">参考情報{{ key }}の画像</label>
            <div class="columns">
              <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
                <img :src="business_doc.business_info[key-1]['image']" width="100px">
              </div>
              <div class="column">
                <photo-resize
                  :image-title="'business_info_image'"
                  :array-num="key-1"
                  :doc-field-title="'business_info'"
                  :src="business_doc.business_info[key-1]['image']"
                  @businessinfoimage="imgUpdate"
                  ref="businessinfoimage"/>
              </div>
            </div>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'business_info_url_' + key">参考情報{{ key }}の詳細URL</label>
            <b-input
              :id="'business_info_url_' + key"
              v-model="business_doc.business_info[key-1]['url']"
              @blur="trim('business_info', key-1, 'url')"
              >
              </b-input>
          </div>
        </b-collapse>
      </div>
    </div>

  </section>
</template>

<script>
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  data () {
    return {
      business_doc: this.$store.getters.business_data || {
        business_info: [
          {
            title: '',
            detail: '',
            image: '',
            url: ''
          }
        ]
      },
      updateFlg: true,
      isOpen: true,
      imageFile: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods :{
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.business_doc, firstProp, this.business_doc[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.business_doc[firstProp], secondProp, this.business_doc[firstProp][secondProp].trim())
      } else {
        this.$set(this.business_doc[firstProp][secondProp], thirdProp, this.business_doc[firstProp][secondProp][thirdProp].trim())
      }
    },
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.businessinfoimage).map(async key => {
        this.$refs.businessinfoimage[key].reset()
      })
    }
  },
  computed : {
    business_title () {
      return this.$store.getters.business_title
    },
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data && Object.keys(this.$store.getters.business_data).length
      ? this.$store.getters.business_data
      : this.$store.getters.admin_data.default_business_data
    },
  },
  watch : {
    // business_dataの監視
    getBusinessData(newValue) {
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
    // 画面上でのbusiness_docの変更をstoreにcommit
    business_doc: {
      handler: function (newVal) {
        this.$store.commit('business_data', newVal)
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.dropdown {
  top: 13px;
  left: -5px;
}
</style>
