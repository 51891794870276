<template>
  <section v-if="page.job_image">
    <label class="label has-text-weight-bold">&nbsp;仕事の画像</label>
    <p v-html="title.job_image_description"></p>
    <div class="content" v-for="key of page.job_image.length" :key="key">
      <div v-if="updateFlg" class="field pb-3">
        <div class="columns">
          <div class="column is-four-fifths">
            <div class="columns">
              <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
                <img :src="page.job_image[key-1]" width="100px">
              </div>
              <div class="column">
                  <photo-resize
                  :image-title="'job_image'"
                  :doc-field-image-title="'image'"
                  :array-num="key-1" :doc-field-title="'job_image'"
                  :business-doc-id="page.business_id"
                  :src="page.job_image[key-1]"
                  @jobimage="imgUpdate"
                  ref="jobimage"/>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="columns">
              <div class="column is-half is-align-self-flex-end is-justify-content-center">
                <b-button
                  v-if="(key-1) !== 0"
                  type="is-ghost"
                  icon-right="arrow-up-bold-outline"
                  size="is-large"
                  @click="menuMove('job_image', (key-1), 'up')"
                />
              </div>
              <div class="column is-align-self-flex-end is-justify-content-center">
                <b-button
                  v-if="(key-1) !== (page.job_image.length - 1)"
                  type="is-ghost"
                  icon-right="arrow-down-bold-outline"
                  size="is-large"
                  @click="menuMove('job_image', (key-1), 'down')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </section>
</template>

<script>
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  data () {
    return {
      page: this.$store.getters.page_data,
      isOpen: true,
      updateFlg: true,
      imageFile: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods :{
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.page, firstProp, this.page[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.page[firstProp], secondProp, this.page[firstProp][secondProp].trim())
      } else {
        this.$set(this.page[firstProp][secondProp], thirdProp, this.page[firstProp][secondProp][thirdProp].trim())
      }
    },
    // photoResize→親へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.jobimage).map(async key => {
        this.$refs.jobimage[key].reset()
      })
    }

  },
  computed : {
    title () {
      return this.$store.getters.title
    },
    // page_dataの監視
    getPageData() {
      return this.$store.getters.page_data && Object.keys(this.$store.getters.page_data).length
      ? this.$store.getters.page_data
      : this.$store.getters.admin_data.default_page_data
    },
  },
  watch : {
    // page_dataの監視
    getPageData(newValue) {
      if(newValue === undefined) return
      this.page = newValue;
    },
  },
}
</script>
