<template>
<section v-if="business_doc" class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu />
    </div>
    <div v-if="business_doc.plan === `standard` && payment_date">
      <Downgrade />
    </div>
    <div v-else class="column">
      <Upgrade />
    </div>
  </div>

</section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import Downgrade from '@/components/payment/Downgrade.vue'
import Upgrade from '@/components/payment/Upgrade.vue'
import Config from '../config.js'

export default {
  components: {
    Menu,
    Downgrade,
    Upgrade
  },
  data () {
    return {
      business_doc: this.$store.getters.business_data,
      charges_doc: this.$store.getters.charges_data,
      amount: Config.AMOUNT,
      plan_change: '',
    }
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getChargesData() {
      return this.$store.getters.charges_data;
    },
    payment_date () {
      return this.charges_doc && this.charges_doc.page && this.charges_doc.page.payment_date
    }
  },
  methods: {
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
    // charges_dataの監視
    getChargesData(newValue) {
      this.charges_doc = newValue;
    },
  },
}
</script>

<style scoped>
</style>
