<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu />
    </div>

    <div class="column">
      <h1 class="is-size-4 has-text-weight-bold">{{ business_doc.business_name + ' ' + business_doc.branch_name }}問合せ内容の一覧</h1>
      <p class="mb-3">メールフォームから送信された問合せ内容の一覧です。ページごとの絞り込み、問合せ日時での並べ替えや問合せ内容などでの検索ができます。</p>
      <hr>
      <p>問合せ内容を表示するページを選択してください</p>
      <div class="select mb-3">
        <b-select id="contact_list" v-model="selected_id" placeholder="選択してください">
          <option v-for="(value, index) in contactList" :key="index" :value="value.id">{{ value.display_name }}</option>
        </b-select>
      </div>

      <div>
        <!-- 参考：https://xaksis.github.io/vue-good-table/ -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            skipDiacritics: true,
            placeholder: '名前、電話番号、メールアドレス、お問合せ内容で検索ができます',
          }"
          :pagination-options="{
            enabled: true,
            setCurrentPage: 1,
            mode: 'records',
            perPage: 20,
            dropdownAllowAll: false,
            jumpFirstOrLast : true,
            firstLabel : '最初のページ',
            lastLabel : '最後のページ',
            nextLabel: '次へ',
            prevLabel: '前へ',
            rowsPerPageLabel: '1ページあたりの行数',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'send_time', type: 'desc'},
          }"
          compactMode
          >
          <div slot="emptystate">
            データがありません。
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Config from '@/config.js'

export default {
  name: 'ContactList',
  components: {
    Menu,
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getPageDocsData() {
      return this.$store.getters.page_docs;
    },
  },
  data(){
    return {
      columns: [
        {
          label: '日時',
          field: 'send_time',
        },
        {
          label: '名前',
          field: 'form_name',
          sortable: false,
        },
        {
          label: '電話番号',
          field: this.formTel,
          html: true,
          sortable: false,
        },
        {
          label: 'メール',
          field: this.formEmail,
          html: true,
          sortable: false,
        },
        {
          label: '問合せ内容',
          field: 'form_content_html',
          html: true,
          sortable: false,
        },
      ],
      rows: [],
      business_doc: this.$store.getters.business_data || {},
      page_docs: this.$store.getters.page_docs || {},
      page : {},
      selected_id : 'all',
      contactList : [
        {
          id : '',
          display_name :''
        }
      ],
    };
  },
  methods: {
    setContactList () {
      // business_name branch_name（全ページ）
      // business_name branch_name
      this.contactList = [
        {
          id : 'all',
          display_name : this.business_doc.business_name + (this.business_doc.branch_name ? (' ' + this.business_doc.branch_name) : '') + '（全ページ）',
        },
        {
          id : this.business_doc.business_id,
          display_name : this.business_doc.business_name + (this.business_doc.branch_name ? (' ' + this.business_doc.branch_name) : ''),
        }
      ];

      Object.keys(this.page_docs).map((page_id) => {
      // jobtype : employment_type
        this.contactList.push({
          id : page_id,
          display_name : this.page_docs[page_id].job_type + '：' + this.employmentTypeJa(this.page_docs[page_id].employment_type)
        })
      })
    },
    // 全ページ or 事業所単位 or 求人ページ単位で絞り込めるようにする
    async listenMailHistory(type) {
      let rows = []
      const business_regex = /[0-9a-zA-Z]{20}/
      const page_regex = /[0-9]{9}/
      let historyData = {}

      switch(true) {
        case type === 'all' :
          historyData = await this.getBusinessAndPageMailHistory();
          break;
        case business_regex.test(type):
          historyData = await this.getMailHistory(type);
          break;
        case page_regex.test(type):
          historyData = await this.getMailHistory(type);
          break;
        default:
          historyData = await this.getBusinessAndPageMailHistory();
          break;
      }

      await Promise.all(
        historyData.map(async data => {
          await rows.push({
            form_name: data.form_name,
            form_tel: data.form_tel,
            form_email: data.form_email,
            form_content_text: data.form_content_text,
            form_content_html: data.form_content_html,
            send_time: data.send_time,
            form_reservation1_date: data.form_reservation1_date,
            form_reservation1_hour: data.form_reservation1_hour,
            form_reservation2_date: data.form_reservation2_date,
            form_reservation2_hour: data.form_reservation2_hour,
          })
        })
      )
      this.rows = await rows
    },
    async getBusinessAndPageMailHistory () {
      let mailHistory = [];
      const businessMailHistory = await this.getMailHistory(this.business_doc.business_id);
      await Promise.all(
        businessMailHistory.map(async doc => {
            await mailHistory.push(doc);
        })
      )
      return await this.getPageDocsMailHistory(mailHistory);
    },
    async getMailHistory(id) {
      let mailHistory = [];
      const querySnapshot = await Config.MAIL_HISTORY_COLLECTION.doc(id).collection('send').get();
      await Promise.all(
        querySnapshot.docs.map(async doc => {
            await mailHistory.push(doc.data());
        })
      )
      return await mailHistory;
    },
    async getPageDocsMailHistory(mailHistory = []) {
      await Promise.all(Object.keys(this.page_docs).map(async page_id => {
        const pageMailHistory = await this.getMailHistory(page_id);
        await Promise.all(
          pageMailHistory.map(async doc => {
              await mailHistory.push(doc);
          })
        )
      }))
      return await mailHistory
    },
    formTel(row) {
      return row.form_tel ? '<a href="tel:' + row.form_tel + '" target="_BLANK">' + row.form_tel + '</a>' : ''
    },
    formEmail(row) {
      return row.form_email ? '<a href="mailto:' + row.form_email + '" target="_BLANK">' + row.form_email + '</a>' : ''
    },
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
      // listにセット
      this.setContactList();
      // 選択を全ページ
      this.selected_id = 'all';
    },
    // page_docsの監視
    getPageDocsData(newValue) {
      this.page_docs = newValue;
      // listにセット
      this.setContactList();
    },
    async selected_id (newValue) {
      // mail history取得
      await this.listenMailHistory(newValue)
    },
  },
}
</script>

<style>

</style>
