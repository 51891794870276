<template>
  <section>
    <div v-if="plan_change === `downgrade`" class="column">
      <h1 class="is-size-4 has-text-weight-bold py-5">決済停止</h1>
      <p>月額利用料の決済を停止しました。</p>
    </div>
    <div v-else-if="plan_change === `upgrade`" class="column">
      <h1 class="is-size-4 has-text-weight-bold py-5">決済完了</h1>
      <p class="py-5">月額利用料の決済が完了しました。</p>

      <h6>月額{{ amount.toLocaleString() }}円（税込）</h6>

      <p>10%対象(税抜)：{{ tax_excluded_price.toLocaleString() }}円</p>
      <p class="pb-5">10%対象消費税：{{ tax.toLocaleString() }}円（税込）</p>

      <p>決済完了のメールをお送りしていますので、ご確認ください。</p>
    </div>
  </section>
</template>

<script>
import Config from '../../config.js'

export default {
  name: 'Complete',
  props: {
    plan_change: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      amount: Config.AMOUNT,
    }
  },
  computed: {
    tax () {
      return this.amount - (this.amount / (1 + Config.TAX_RATE));
    },
    tax_excluded_price () {
      return this.amount - this.tax;
    },
  },
}
</script>
