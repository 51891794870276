import Vue from 'vue/dist/vue.esm'
import Router from 'vue-router'
import Vuex from 'vuex'
import Main from '@/components/Main'
import Business from '@/components/Business'
import Page from '@/components/Page'
import Login from '@/components/Login'
import MailChange from '@/components/MailAddressChange'
import PasswordReset from '@/components/PasswordReset'
import PasswordChange from '@/components/PasswordChange'
import Faq from '@/components/Faq'
import SignUp from '@/components/SignUp'
import Flyer from '@/components/Flyer'
// import Report from '@/components/Report'
import Referral from '@/components/Referral'
import QrLink from '@/components/QrLink'
import ContactList from '@/components/ContactList'
import Payment from  '@/components/Payment'
import CardChange from  '@/components/CardChange'
import TransferUrl from '@/components/TransferUrl'
import Footer from '@/components/Footer'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueSweetalert2 from 'vue-sweetalert2'
import VeeValidate, { Validator } from 'vee-validate'
import 'sweetalert2/dist/sweetalert2.min.css'
import ja from 'vee-validate/dist/locale/ja'
import Config from './config.js'
import mixInFunction from './mixInFunction.js';
import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import moment from 'moment'
Vue.mixin(mixInFunction);

Vue.config.productionTip = false
Vue.use(Router)
Vue.use(Vuex)
Vue.use(Buefy)
Vue.use(VueSweetalert2)
Vue.use(VeeValidate)
Validator.localize('ja', ja)
Vue.use(VueGoodTablePlugin);

const App = {
  template: `
    <div>
      <Main
      ref="main" />
    </div>
    `,
  components: { Main },
  // // 保存前のページ移動を制限する
  // beforeRouteLeave (to, from, next) {
  //   if(from.path === '/' && to.path !== '/')
  //    {
  //     this.$swal({
  //       title: '変更内容を保存しましたか？',
  //       text: 'ページを移動すると、未保存の内容は破棄されます。',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'ページを移動する',
  //       cancelButtonText: 'キャンセル'
  //     }).then(result => {
  //       if (result.value) {
  //         next()
  //       } else {
  //         next(false)
  //         // メニュー表示を変更しない(Main.vue mixin Menu.vueのactiveMenuメソッド)
  //         this.$refs.main.activeMenu(['/'], from.path, to.path)
  //         }
  //     })
  //     return
  //   }
  //   next()
  // }
}

let app
Config.FIREBASE_AUTH.onAuthStateChanged(
  p => {
    if (app) {
      // vueオブジェクトが存在した場合、storeのuserを呼び出し、pを入れている
      app.$store.commit('user', p)
    } else {
      const router = new Router(
        {
          mode: 'history',
          routes:
            [
              { path: '/login', component: Login },
              { path: '/signup', component: SignUp },
              { path: '/mailchange', component: MailChange },
              { path: '/passwordreset', component: PasswordReset },
              { path: '/passwordchange', component: PasswordChange },
              { path: '/flyer', component: Flyer },
              { path: '/faq', component: Faq },
              { path: '/referral', component: Referral},
              { path: '/business', component: Business },
              { path: '/business/:id', component: Business },
              { path: '/page', component: Page },
              { path: '/flyer', component: Flyer },
              { path: '/qr', component: QrLink },
              { path: '/ad', component: Page },
              { path: '/contact_list', component: ContactList },
              { path: '/payment', component: Payment },
              { path: '/card', component: CardChange },
              { path: '/transfer_url', component: TransferUrl },
              { path: '/payment/:id', component: Payment },
              { path: '/card/:id', component: CardChange },
              { path: '/:id/flyer', component: Flyer },
              { path: '/:id', component: App },
              { path: '/main', component: App }, // 初回遷移用
              { path: '/', component: App },
              { path: '/*', component: { template: '<div>NotFound</div>' } }
            ],
          scrollBehavior: (to, from, savedPosition) => {
            if (savedPosition) {
                return savedPosition;
            }

            if (to.hash) {
                return {selector: to.hash}
            }
            return {x: 0, y: 0};
          }
        }
      )
      router.beforeEach(
        async (to, from, next) => {
          // リファラーの設定
          router['referrer'] = from;
          if (Config.FIREBASE_AUTH.currentUser) {
            // loginページ指定の場合はサインアウト
            if (to.path === '/login') await Config.FIREBASE_AUTH.signOut()
            // TOPページ指定の場合にはそのまま表示
            next()
          } else {
            if (from.path !== '/signup') {
              next()
              // next({ path: 'signup', query: { redirect: to.path } })
            } else if (
              to.path !== '/signup' &&
              to.path !== '/login' &&
              to.path !== '/passwordreset'
            ) {
              next({ path: 'login', query: { redirect: to.path } })
            } else {
              next()
            }
          }
        }
      )
      app = new Vue(
        {
          el: '#app',
          components : {
            Footer
          },
          store: new Vuex.Store(
            {
              state: {
                user: p,
                page_data: {},
                business_data:{},
                page_docs:{}, // 事業所ごとの求人ページのリスト
                referral_data:{},
                charges_data: {},
                charges_docs: {},
                ads_data: {},
                sales_data: {},
                admin_data:{},
                title: {},
                business_title: {},
                prevRoute: '',
                payment_date: '',      // 有料プランの決済日
                last_payment_date: '',  // 有料プランの最終決済日
                new_business_id: '',
                new_page_id: '',
              },
              getters : {
                admin_data : (state) => state.admin_data,
                business_data : (state) => state.business_data,
                page_docs: (state) => state.page_docs,
                page_data : (state) => state.page_data,
                charges_data : (state) => state.charges_data,
                charges_docs : (state) => state.charges_docs,
                ads_data : (state) => state.ads_data,
                sales_data : (state) => state.sales_data,
                title: (state) => state.title,
                business_title :(state) => state.business_title,
                payment_date : (state) => state.payment_date,
                last_payment_date : (state) => state.last_payment_date
              },
              mutations: {
                user: function (state, payload) {
                  state.user = payload
                },
                page_docs : function (state, payload) {
                  state.page_docs = payload
                },
                page_data: function (state, payload) {
                  state.page_data = payload
                },
                business_data: function (state, payload) {
                  state.business_data = payload
                },
                referral_data: function (state, payload) {
                  state.referral_data = payload
                },
                charges_data: function (state, payload) {
                  state.charges_data = payload
                },
                charges_docs: function (state, payload) {
                  state.charges_docs = payload
                },
                ads_data: function (state, payload) {
                  state.ads_data = payload
                },
                sales_data: function (state, payload) {
                  state.sales_data = payload
                },
                admin_data: function (state, payload) {
                  state.admin_data = payload
                },
                title : function (state, payload) {
                  state.title = payload
                },
                business_title : function (state, payload) {
                  state.business_title = payload
                },
                prevRoute: function (state, payload) {
                  state.prevRoute = payload
                },
                payment_date: function (state, payload) {
                  state.payment_date = payload
                },
                last_payment_date: function (state, payload) {
                  state.last_payment_date = payload
                }
              },
              actions : {
                // 求人ページリストの更新
                async updatePageDocs ({ state }, business_id) {
                  // ページのリストを取得
                  const querySnapshot = await Config.COLLECTION
                  .where('business_id', '==', business_id)
                  .where('deleted', '==', false)
                  .get()

                  // ページのデータ群
                  let page_docs = []
                  querySnapshot.forEach(p => {
                    page_docs[p.id] = p.data()
                  })
                  state.page_docs = page_docs
                },
                // 決済データの更新
                async updateChargesData ({ state } , { doc_id, data }) {
                  // 保存時刻の設定
                  data.date = moment(moment().unix(),'X').format()
                  // 決済データの更新
                  await Config.CHARGES_COLLECTION.doc(doc_id)
                  .set(data, {merge : true })

                  // 更新後のデータを取得
                  const snapshot = await Config.CHARGES_COLLECTION.doc(doc_id).get()
                  state.charges_data = snapshot.data()
                },
                // 事業所データの更新
                async updateBusinessData ({ state } , { doc_id, data }) {
                  console.log('updateBusinessData', state)
                  // 保存時刻の設定
                  data.date = moment(moment().unix(),'X').format()
                  // 事業所データの更新
                  await Config.BUSINESS_COLLECTION.doc(doc_id).set(data, {merge : true })

                  // 更新後のデータを取得
                  const snapshot = await Config.BUSINESS_COLLECTION.doc(doc_id).get()
                  state.business_data = await snapshot.data()
                },
                // 求人データの更新
                async updatePageData ({ state } , { doc_id, data }) {
                  // console.log('updatePageData', state, data)
                  // 保存時刻の設定
                  data.date = moment(moment().unix(),'X').format()
                  // 求人データの更新
                  await Config.COLLECTION.doc(doc_id).set(data, {merge : true })

                  // 更新後のデータを取得
                  const snapshot = await Config.COLLECTION.doc(doc_id).get()
                  state.page_data = await snapshot.data()
                },
                // 決済データの削除
                async deleteChargesData ({ state }, doc_id) {
                  console.log('deleteChargesData', state)
                  // 決済データの更新
                  await Config.CHARGES_COLLECTION.doc(doc_id)
                    .set({ deleted : true , date: moment(moment().unix(),'X').format() }, {merge : true })
                },
                // 事業所データの削除
                async deleteBusinessData ({ state } , doc_id) {
                  console.log('deleteBusinessData', state)
                  // 事業所データの更新
                  await Config.BUSINESS_COLLECTION.doc(doc_id)
                    .set({ deleted : true , date: moment(moment().unix(),'X').format() }, {merge : true })
                },
                // 求人データの削除
                async deletePageData ({ state } , doc_id) {
                  console.log('deletePageData', state)
                  // 求人データの更新
                  await Config.COLLECTION.doc(doc_id)
                    .set({ deleted : true , date: moment(moment().unix(),'X').format() }, {merge : true })
                },
                // 紹介データの削除
                async deleteReferralData ({ state } , doc_id) {
                  console.log('deleteReferralData', state)
                  // 紹介データの更新
                  await Config.REFERRAL_COLLECTION.doc(doc_id)
                    .set({ deleted : true , date: moment(moment().unix(),'X').format() }, {merge : true })
                },
                // 決済データ取得
                async getChargesData ({ state }, doc_id) {
                  const snapshot = await Config.CHARGES_COLLECTION.doc(doc_id).get()
                  const charges_data = await snapshot.data()
                  state.charges_data = charges_data

                  const regex = /[0-9a-zA-Z]{20}/

                  // business_idだった場合のみ
                  if(regex.test(doc_id)) {
                    if (charges_data && charges_data.page) {
                      // 画面上で利用する決済日
                      state.payment_date = await charges_data.page.payment_date ? charges_data.page.payment_date : ''
                      // 画面上で利用する最終決済日
                      state.last_payment_date = await charges_data.page.last_payment_date ? charges_data.page.last_payment_date : ''
                    } else{
                      // 決済なしの事業所の場合にはリセット
                      state.payment_date = ''
                      state.last_payment_date =''
                    }
                  }
                },
                // 決済データリスト（広告系）取得
                async getChargesDocs ({ state }, business_id) {
                  const querySnapshot = await Config.CHARGES_COLLECTION
                  .where('business_id', '==', business_id)
                  .where('deleted', '==', false)
                  .get()

                  let charges_docs = []
                  querySnapshot.forEach(p => {
                    charges_docs[p.id] = p.data()
                  })
                  state.charges_docs = charges_docs
                },
                // 事業所データ取得
                async getBusinessData ({ state }, doc_id) {
                  const snapshot = await Config.BUSINESS_COLLECTION.doc(doc_id).get()
                  if(snapshot.exists) {
                    state.business_data = snapshot.data()
                  } else {
                    state.business_data = {}
                  }
                },
                // 紹介データ取得
                async getReferralData ({ state }, doc_id) {
                  const snapshot = await Config.REFERRAL_COLLECTION.doc(doc_id).get()
                  state.referral_data = snapshot.data()
                },
                // 求人ページデータ取得
                async getPageData ({ state }, doc_id) {
                  const snapshot = await Config.COLLECTION.doc(doc_id).get()
                  if(snapshot.exists) {
                    state.page_data = snapshot.data()
                  } else {
                    state.page_data = {}
                  }
                },
                // 求人ページのリスト取得
                async getPageDocs ({ state }, business_id) {
                  // ページのリストを取得
                  const querySnapshot = await Config.COLLECTION
                    .where('business_id', '==', business_id)
                    .where('deleted', '==', false)
                    .get()

                  // ページのデータ群
                  let page_docs = {}
                  querySnapshot.forEach(p => {
                    page_docs[p.id] = p.data()
                  })
                  state.page_docs = page_docs
                },
                // 営業データ取得
                async getSalesData ({ state }, doc_id) {
                  const snapshot = await Config.SALES_COLLECTION.doc(doc_id).get()
                  if(snapshot.exists) {
                    state.sales_data = snapshot.data()
                  } else {
                    state.sales_data = {}
                  }
                },
                // 広告データ取得
                async getAdsData ({ state }, doc_id) {
                  const snapshot = await Config.ADS_COLLECTION.doc(doc_id).get()
                  if(snapshot.exists) {
                    state.ads_data = snapshot.data()
                  } else {
                    state.ads_data = {}
                  }
                },
                // 管理者データ取得
                async getAdminData ({ state }) {
                  if(!state.user) {
                      alert('再度ログインしてください')
                      // loginページへ
                      await Config.FIREBASE_AUTH.signOut()
                      // アクセスしてきたURLをredirectにセット
                      location.href = '/login?redirect=' + location.pathname
                      return
                  }

                  let admin_doc = {};
                  const snapshot = await Config.ADMIN_COLLECTION.get()

                  if (!snapshot.size){
                    let result = await this.$swal.fire({
                        title: 'エラー',
                        text: 'サービスデータの取得に失敗しました。再度、ログインし直してください。',
                        icon: 'error',
                    })

                    if(result.value) {
                      await Config.FIREBASE_AUTH.signOut()
                      // loginページへ
                      location.href = Config.MANAGE_URL + '/login'
                      return
                    }
                  }

                  snapshot.forEach(p => {
                    admin_doc[p.id] = p.data()
                  })

                  admin_doc.default_business_data.uid = state.user.uid
                  state.admin_data = admin_doc
                },
                // 事業所タイトル取得
                async getBusinessTitle ({ state }) {
                  state.business_title = state.admin_data.title_business_data
                },
                // タイトル取得
                async getPageTitle ({ state }) {
                  state.title = state.admin_data.title_page_data
                },
                async newBusinessId ({ state }) {
                  state.new_business_id = await Config.BUSINESS_COLLECTION.doc().id
                  return state.new_business_id
                },
                async newPageId ({ state }) {
                  let maxLpNo = [];
                  const snapshot = await Config.COLLECTION.orderBy('page_id', 'desc').limit(3).get()

                  await snapshot.docs.map(doc =>  {
                    // doc.idが数字だった場合
                    if (!isNaN(doc.id)) {
                      maxLpNo.push(doc.id);
                    }
                  })

                  if (maxLpNo.length === 0) {
                    return '000000001'
                  }

                  state.new_page_id = await ('000000000' + (Number(Math.max.apply(null, maxLpNo)) + 1)).slice(-9)
                  return state.new_page_id
                },
              },
            }
          ),
          router,
          template: `
            <section class="section container">
              <router-view />
              <Footer v-if="$route.path !== '/login'" />
            </section>`
        }
      )
    }
  }
)
