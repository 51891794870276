<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu />
      </div>
      <div class="column">
        <div class="pb-3">
          <h1 class="is-size-4 has-text-weight-bold py-1">{{ business_doc.business_name }} 求人情報の{{ Object.keys(page).length ? `入力・修正` : `新規作成` }}</h1>
          <p class="">
            求人情報を入力してください。
          </p>
          <p v-if="disabled" class="has-text-danger">
            必須項目が未入力です。
          </p>
          <p v-else>
            <span v-if="page && page.page_id">
              {{ page.job_type }}：{{ employmentTypeJa(page.employment_type) }}
              <br>
              {{ destination_url + `/page/` + page.page_id }}
            </span>
          </p>

          <!-- <b-message v-if="!pageUrlCheck" type="is-danger is-light mb-3">
            必須項目を全て入力すると求人ページが公開されます。<br>
          </b-message> -->
        </div>

        <JobDescription
          @imgUpdate="imgUpdate"
          ref="jobimage"
        />
        <Voice
          @imgUpdate="imgUpdate"
          ref="voiceimage"
        />
        <Qa
          class="pb-5"
        />
        <JobReferenceInfo
          @imgUpdate="imgUpdate"
          ref="jobinfoimage"
        />

        <div class="columns pt-1 pb-2 has-text-centered">
          <div v-if="page.page_id" class="column is-align-self-center">
            <b-button
              label="ページの削除"
              class="button"
              @click="remove()"
              type="is-text"
              >
            </b-button>
          </div>
        </div>

        <div class="bottom-menu pt-1 pb-2">
            <div class="mt-1">
            <b-button
              :disabled="disabled"
              class="button is-info"
              @click="save_submit()"
              size="is-large"
              expanded
            >
              <span>
                保存する
              </span>
            </b-button>
          </div>
        </div>
        <p v-if="disabled" class="has-text-danger">
          必須項目が未入力です。
        </p>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import moment from 'moment'
import Config from '../config.js'
import Qa from '@/components/page/FrequentlyAskedQuestions.vue'
import Voice from '@/components/page/VoiceOfStaff.vue'
import JobDescription from '@/components/page/JobDescription.vue'
import JobReferenceInfo from '@/components/page/JobReferenceInfo.vue'
import Compressor from 'compressorjs';

export default {
  components: {
    Menu,
    Qa,
    Voice,
    JobDescription,
    JobReferenceInfo
  },
  computed : {
    title () {
      return this.$store.getters.title
    },
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    // page_dataの監視
    getPageData() {
      return this.$store.getters.page_data && Object.keys(this.$store.getters.page_data).length
      ? this.$store.getters.page_data
      : this.$store.getters.admin_data.default_page_data
    },
    destination_url () {
      return Config.DESTINATION_URL
    },
    // ボタンのアクティブ
    disabled : {
      get () {
        // 必須項目が全て入力されていたらボタンをアクティブにする
        return !this.pageUrlCheck()
      },
      set (value) {
        return value
      }
    },
  },
  watch : {
    // business_dataの監視
    getBusinessData(newValue) {
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
    // page_dataの監視
    getPageData(newValue) {
      if(newValue === undefined) return
      this.page = newValue;
    },
  },
  methods: {
    pageUrlCheck () {
      if (this.page
        && this.page.job_type
        && this.page.employment_type
        && this.page.job_description
        && this.page.salary_min
        && this.setSalaryDisplayType  // 給与表示タイプ
        )
      {
        return true
      }
      return false
    },
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.page, firstProp, this.page[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.page[firstProp], secondProp, this.page[firstProp][secondProp].trim())
      } else {
        this.$set(this.page[firstProp][secondProp], thirdProp, this.page[firstProp][secondProp][thirdProp].trim())
      }
    },
    /**
     * 給与表示タイプ
     */
    setSalaryDisplayType () {
      if (this.page.salary_min) {
        if (this.page.salary_max) {
          return 'RANGE'
        } else {
          // min 以上
          if (this.page.salary_display_type === 'MIN') {
            return 'MIN'
          }
          // min のみ
          return 'FIXED'
        }
      }
      return ''
    },
    /**
     * 画像アップロード処理（保存ボタン押した時に発火）
     * 各子コンポーネント上での画像アップロードデータをimageDataに入れる
     * 配列上に保存しておいて保存ボタンで一気にアップロード＋削除
     */
    imgUpdate (imageFileData) {
      // 配列の初期化
      if (this.imageData[imageFileData['imageTitle']] === undefined) {
        this.imageData[imageFileData['imageTitle']] = []
      }

      // 配列でない場合にarrayNumは0
      this.imageData[imageFileData['imageTitle']][imageFileData['arrayNum'] || 0] = imageFileData
    },
    /**
     * ページ保存ボタン送信
     */
    async save_submit () {
      // ボタンを非アクティブ
      this.disabled = true
      // loading
      this.isLoading = true

      const page_id = await this.page.page_id || await this.$store.dispatch('newPageId')

      // business_idの設定
      await this.$set(this.page, 'business_id', this.business_doc.business_id)

      // page_idの設定
      await this.$set(this.page, 'page_id', page_id)
      // 給与表示タイプ
      await this.$set(this.page, 'salary_display_type', this.setSalaryDisplayType())

      // 画像関連の変更がある場合には保存もしくは削除
      if(Object.keys(this.imageData).length !== 0){
        // 画像変更あり時にbusiness_docを上書き
        this.page = await this.uploadAndRemove()
      }

      try {
        await this.save()
      } catch (e) {
        this.disabled = false
        // loading
        this.isLoading = false
        await this.$swal('error', '保存に失敗しました：' + e, 'error')
        return
      }
      this.disabled = false
      // loading
      this.isLoading = false

      // storeのstateを置き換える
      // await this.$store.commit('page_data', this.page)

      // 更新のdispatch
      await this.$store.dispatch('updatePageDocs', this.business_doc.business_id)

      // TOP(求人一覧へ転送)
      await this.$router.push("/main").catch(() => {})

      return
    },
    /**
     * 画像アップロードor削除
     */
    async uploadAndRemove() {
      // アップロードおよび削除予定の画像他の情報
      const imageFileData = this.imageData

      let doc = this.page

      // アップロード＋page_dataへのURL保存
      const uploadAndRemove = (data) => {
        // Promise を返却
        return new Promise((resolve) => {
          Object.keys(data).map(imageTitle => {
            Object.keys(data[imageTitle]).map(arrayNum => {
              const image = data[imageTitle][arrayNum]
              // 削除の場合には飛ばす
              if(!image.isDeleted) {
                doc = this.imgUpload(imageTitle, arrayNum, image)
              } else {
                // 削除対象のURLがあれば
                if (image.src) {
                  doc = this.imgRemove(imageTitle, arrayNum, image)
                }
              }
            })
          })
          resolve(doc)
        })
      }
      return await uploadAndRemove(imageFileData) // Promise が返却される
    },
    /**
     * 画像アップロード
     */
    async imgUpload(imageTitle, arrayNum, image) {
      // firebase storage
      const storage = Config.FIREBASE_STORAGE;

      // 画像のアップロード先の準備（ファイル名は新規作成）
      const mountRef = await storage
        .ref()
        .child(
          Config.STORAGE_DIR +
            "/" +
            this.page['page_id'] +
            "/" +
            imageTitle +
            "_" +
            (Math.random().toString(36).slice(-8)) +
            ".jpg"
        );

      // 圧縮+jpg化+アップロード+page_dataへのURL保存
      return await this.compress(image, mountRef, arrayNum, imageTitle)
    },
    /**
     * 画像アップロード時の圧縮・保存
     */
    async compress(image, mountRef, arrayNum, imageTitle) {
      // const deleteFileUrl = image.src

      const promisfiedCompressor = ( file, q=0.92, maxWidth=1200, mimeType='image/jpeg') => {
        // Promise を返却
        return new Promise((resolve, reject) => {

          new Compressor(file, {
            quality: q,
            maxWidth:maxWidth,
            mimeType: mimeType,
            success(blob) {
              resolve(blob); // Promise を 成功終了
            },
            error(err) {
              reject(err); // Promise を 異常終了
            }
          }); // Compressor.js
        });
      }

      const file = await promisfiedCompressor(image.file) // Promise が返却される

      try {
        // 画像アップロード保存
        const snapshot = await mountRef.put(file)
        // 保存後のURL取得
        const url = await snapshot.ref.getDownloadURL()

        let docFieldData = this.page[image.docFieldTitle]

        // business_image|staff_image|line_image|offer_imageの場合は別処理
        switch (imageTitle) {
          case 'job_image':
            docFieldData[arrayNum] = url
            this.page[image.docFieldTitle] = docFieldData;
            break;

          default:
            // job_info/voice
            docFieldData[arrayNum]['image'] = url
            this.page[image.docFieldTitle] = docFieldData;
            break;
          }
          // // 既存ファイル削除
          // 20230803 Elastic Searchのため実ファイルは削除しない方向に
          // if(deleteFileUrl) {
          //   await this.deleteStorageFile(deleteFileUrl);
          // }
        } catch (error) {
          console.log(error)
          this.$swal("画像アップロードエラー", "エラー内容：" + error, "error");
        }

      // 画面上のcanvas削除 refsを使って子コンポーネントのreset関数呼び出し
      this.$refs[imageTitle.replace(/_/g, "").replace(/[0-9]/g, "")].reset();
      return this.page
    },
    /**
     * 画像削除
     * imageTitleの種類は以下の３種類
     * job_image
     * job_info
     * voice
     */
    async imgRemove(imageTitle, arrayNum, image) {
      const db = Config.COLLECTION.doc(this.page['page_id'])
      let doc = this.page

      let docFieldData = this.page[image.docFieldTitle]

      switch (imageTitle) {
        case 'job_image':
          docFieldData[arrayNum] = ""
          doc[image.docFieldTitle][arrayNum] = ''
          await db.update({ [image.docFieldTitle] : docFieldData })
          break;

        default:
          // job_info|voice
          docFieldData[arrayNum]['image'] = ""
          doc[image.docFieldTitle][arrayNum]['image'] = ''
          await db.update({ [image.docFieldTitle] : docFieldData })
          break;
      }

      // storageからファイル削除
      // await this.deleteStorageFile (image.src);
      return this.page
    },
    /**
     * ページデータのまとめて保存
     */
    async save() {
      // page.salary_maxは空の場合には0に変換
      if (!this.page.salary_max) {
        await this.$set(this.page, 'salary_max', 0)
      }
      // 更新時間
      await this.$set(this.page, 'date', moment(moment().unix(),'X').format('YYYY/MM/DD'))

      // バッチ保存に変更
      try {
        await this.$store.dispatch('updatePageData', {
          doc_id: this.page.page_id,
          data: this.page
        })
      } catch(e) {
        await this.$swal('error', '保存エラー：' + e, 'error')
        return
      }

      await this.$swal.fire({
        title: '',
        html:'<b>変更を保存しました</b>' + '<br>'
          + this.business_doc.business_name
          + (this.business_doc.branch_name ? ` ` + this.business_doc.branch_name : '')
          + '<br>'
          + this.page.job_type + '：' + this.employmentTypeJa(this.page.employment_type)
          + '<br>'
          + '<a href="'
          + Config.DESTINATION_URL + '/page/' + this.page.page_id
          + '" target="_BLANK">'
          + Config.DESTINATION_URL + '/page/' + this.page.page_id
          + '</a>',
        icon: 'success',
        confirmButtonText: '閉じる',
        allowOutsideClick: false
      })
      return
    },
    /**
     * ページ削除
     */
    async remove () {
      let page_id = this.page.page_id
      let result = await this.$swal.fire(
        {
        title: '本当に削除しますか？',
        html:
            this.page.job_type + '：' + this.employmentTypeJa(this.page.employment_type) + '<br>' +
            'https://jp.ilb.jobs/' + this.page.page_id + '<br>' + '<br>' +
            '求人ページの全データが削除されます' + '<br>' +
            '削除したページは復旧できません',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "キャンセル",
        confirmButtonColor: '#d33',
        confirmButtonText: '削除する',
      }
      )
      if (result.value) {
        this.isLoading = true

        try {
          // business_dataのdelete
          await this.$store.dispatch('deletePageData', page_id)
          // charges_dataのdeleted
          await this.$store.dispatch('deleteChargesData', this.page_id)
          // 画面更新
          await location.reload()
        } catch(e) {
          this.$swal('error', '削除エラー：' + e, 'error')
        }
        this.isLoading = false
      }
    },
  },
  data () {
    return {
      imageData: {},
      business_doc: this.$store.getters.business_data,
      page :this.$store.getters.page_data,
      isLoading: false,
      isFullPage: true,
    }
  },
}

</script>

<style scoped>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.bottom-menu {
  position: fixed;
  bottom: 20px;
  text-align: center;
  z-index: 4;
  /* 背景色薄いグレー */
  background: rgba(255, 255, 255, 0.9);
}

/* スマホ */
@media screen and (max-width: 768px) {
  .bottom-menu {
    width: 80%;
    left: 10%;
    right: 10%;
  }
}

/* PC */
@media screen and (min-width: 769px) {
  .bottom-menu {
    width: 60%;
    left: 30%;
  }
}

</style>
