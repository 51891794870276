<template>
  <div class="mobile-margin">
    <b-navbar fixed-top v-if="window_size <= 769">
      <template #burger>
        <b-navbar-item tag="div">
          <Slide>
            <b-menu v-if="selectedBusinessId">
              <b-menu-list>
                <b-menu-item icon="square-edit-outline" label="事業所情報の入力・修正" @click="goPage('business')"></b-menu-item>
                <b-menu-item icon="star-circle" label="有料プランに変更する" @click='$router.push( "/payment" ).catch(() => {})' />
                <b-menu-item icon="file-outline" label="チラシの作成" @click='$router.push( "/flyer" ).catch(() => {})'></b-menu-item>
                <b-menu-item icon="format-list-bulleted" label="求人ページの一覧" @click='$router.push( "/" ).catch(() => {})' />
                <b-menu-item icon="plus-circle-outline" label="求人ページを作成" @click="goPage('page')" />
                <b-menu-item icon="file-chart" label="レポートの確認"  @click='$router.push( "/report" ).catch(() => {})'></b-menu-item>
                <b-menu-item icon="email-arrow-left-outline" label="問い合わせ一覧"  @click='$router.push( "/contact_list" ).catch(() => {})'></b-menu-item>
                <b-menu-item id="flyer" icon="file-outline" label="求人チラシの作成" @click='$router.push( "/flyer" ).catch(() => {})'></b-menu-item>
                <b-menu-item icon="qrcode" label="QRコードの作成" @click='$router.push( "/qr" ).catch(() => {})'></b-menu-item>
              </b-menu-list>
            </b-menu>

            <b-menu>
              <b-menu-list>
                <b-menu-item icon="plus-circle-outline" label="事業所ページの作成" @click="newBusiness()"></b-menu-item>
                <b-menu-item icon="help-circle-outline" label="よくある質問" @click='$router.push( "/faq" ).catch(() => {})'></b-menu-item>
                <b-menu-item icon="email" label="メールアドレスの変更" @click='$router.push( "/mailchange" ).catch(() => {})'></b-menu-item>
                <b-menu-item icon="lock-reset" label="パスワードの変更" @click='$router.push( "/passwordchange" ).catch(() => {})'></b-menu-item>
                <b-menu-item icon="exit-to-app" label="ログアウト" @click='$router.push( "/login" ).catch(() => {})'></b-menu-item>
                <b-menu-item v-if="Object.keys(page_docs).length === 0" icon="close" label="アカウントの削除" @click='withdrawal()'></b-menu-item>
              </b-menu-list>
            </b-menu>


          </Slide>
        </b-navbar-item>
        <b-navbar-item tag="div">
          リクルートマネージャー
        </b-navbar-item>
      </template>
    </b-navbar>

    <p class="has-text-weight-bold pb-1" v-if="window_size > 770">
      リクルートマネージャー
    </p>

    <div v-if='Object.keys(business_list).length' class="block" style="overflow-wrap: break-word;">
      <div class='field'>
        <label class='label'>事業所を選択してください</label>
        <b-select v-model='selectedBusinessId' @focus="onFocus" placeholder="事業所の新規作成">
          <option v-if="mode === `new`" value="" disabled>事業所の新規作成</option>
          <option v-for='business in business_list' :key='business.id' :value='business.id'>{{ business.business_name }} {{ business.branch_name }}</option>
        </b-select>
      </div>

      <div v-if="business_doc && selectedBusinessId" class="mb-3">
        <div class="pb-3">
          事業所ページ（
          <span v-if="pageUrlCheck">
            公開中
          </span>
          <span v-else>
            <span class="has-text-danger">非公開</span>
          </span> ）<br>
          <a :href="'https://jp.ilb.jobs/' + (business_doc['transfer_id'] || selectedBusinessId)" target="_blank">
            <div class="business-url ">
              <div class="business-url-item">
                {{ destination_url + '/' + (business_doc['transfer_id'] || selectedBusinessId) }}
              </div>
            </div>
          </a>

          <div v-if="!pageUrlCheck">
            <p class="has-text-danger">
              ※現在ページは非公開となっていますので、必須項目を入力してください。
            </p>
          </div>
        </div>
        <div v-if="business_doc['plan'] === 'standard'" class="pb-3" >
          <div v-if="payment_date">
            <!-- <p>
              有料プランを利用中
            </p> -->
            <p>
              決済日：毎月{{ payment_date }}日
            </p>
            <div id="card">
              <p>
                <a @click='$router.push( "/card" ).catch(() => {})'>クレジットカードを変更する</a>
              </p>
            </div>
          </div>
        </div>
        <div id="transfer_url">
          <p v-if="!business_doc['transfer_id']" class="help">
            事業所ページにご希望の転送用URLを設定できます
          </p>
          <b-button
            class="button"
            @click='$router.push( "/transfer_url" ).catch(() => {})'
            expanded
          >
            転送用URLを{{ business_doc['transfer_id'] ? `変更` : `設定` }}する
          </b-button>
        </div>

        <div v-if="!payment_date && $route.path !== '/payment'" class="pb-3">
          <div v-if="business_doc['plan'] === 'standard'">
            <p>
              決済を再開してください
            </p>
            <p>
              <span class="has-text-danger">
                {{ expired(last_payment_date) }}
              </span>
              <span>
                を過ぎると、ご利用いただける機能が制限されます。全ての機能をご利用いただくためには、ご利用料金の決済を再開してください。
              </span>
            </p>
            <p>
              <b-button
                class="button is-info"
                @click='$router.push( "/payment" ).catch(() => {})'
                expanded
              >
                決済を再開する
              </b-button>
            </p>
          </div>
          <div v-else>
            <p class="has-text-weight-bold pt-5">
              決済を完了してください
            </p>
            <p>
              <span>
                現在、ご利用いただける機能が制限されています。全ての機能をご利用いただくためには、ご利用料金の決済を完了してください。
              </span>
            </p>
            <p>
              <b-button
                class="button is-info"
                @click='$router.push( "/payment" ).catch(() => {})'
                expanded
              >
                決済画面に移動する
              </b-button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="window_size > 770">
      <b-menu v-if="selectedBusinessId" class="box">
        <b-menu-list label="ページ">
          <b-menu-item id="business" icon="square-edit-outline" :active="activeMenu('/business')" label="事業所情報の入力・修正" @click="goPage('business')"></b-menu-item>
          <b-menu-item id="main" icon="format-list-bulleted" :active="activeMenu('/') || activeMenu('/main')" label="求人ページの一覧" @click='$router.push( "/" ).catch(() => {})' />
          <b-menu-item id="page" icon="plus-circle-outline" :active="activeMenu('/page')" label="求人ページを作成" @click="goPage('page')" />
          <b-menu-item id="remove" v-if="Object.keys(page_docs).length === 0" icon="close" label="事業所ページを削除" @click='remove()'></b-menu-item>
        </b-menu-list>
      </b-menu>

      <b-menu v-if="selectedBusinessId" class="box">
        <b-menu-list label="分析・改善">
          <!-- <b-menu-item id="report" icon="file-chart" label="レポートの確認（工事中）" :active="activeMenu('/report')" @click='$router.push( "/report" ).catch(() => {})'></b-menu-item> -->
          <b-menu-item id="contact_list" icon="email-arrow-left-outline" label="問い合わせ一覧" :active="activeMenu('/contact_list')" @click='$router.push( "/contact_list" ).catch(() => {})'></b-menu-item>
          <b-menu-item id="flyer" icon="file-outline" :active="activeMenu('/flyer')" label="求人チラシの作成" @click='$router.push( "/flyer" ).catch(() => {})'></b-menu-item>
          <b-menu-item id="qr" icon="qrcode" label="QRコードの作成" target="_blank" :active="activeMenu('/qr')" @click='$router.push( "/qr" ).catch(() => {})'></b-menu-item>
        </b-menu-list>
      </b-menu>


      <b-menu class="box">
        <b-menu-list label="管理">
          <b-menu-item id="business-new" icon="plus-circle-outline" :active="activeMenu('/business/new')" label="事業所ページの作成" @click="newBusiness()"></b-menu-item>
          <b-menu-item id="faq" icon="help-circle-outline" :active="activeMenu('/faq')" label="よくある質問" @click='$router.push( "/faq" ).catch(() => {})'></b-menu-item>
          <b-menu-item id="mailchange" icon="email" label="メールアドレスの変更" @click='$router.push( "/mailchange" ).catch(() => {})'></b-menu-item>
          <b-menu-item id="passwordchange" icon="lock-reset" label="パスワードの変更" @click='$router.push( "/passwordchange" ).catch(() => {})'></b-menu-item>
          <!-- <b-menu-item v-if="passwordAuthProvider" id="change-auth-method" icon="account-convert-outline" label="パスワードログインの削除" @click="changeAuthMethod('password', 'unlink')"></b-menu-item>
          <b-menu-item v-else id="change-auth-method" icon="account-convert-outline" label="パスワードログインの追加" @click="changeAuthMethod('password', 'link')"></b-menu-item>
          <b-menu-item v-if="googleAuthProvider" id="change-auth-method" icon="account-convert-outline" label="Googleログインの削除" @click="changeAuthMethod('google', 'unlink')"></b-menu-item>
          <b-menu-item v-else id="change-auth-method" icon="account-convert-outline" label="Googleログインの追加" @click="changeAuthMethod('google', 'link')"></b-menu-item> -->
          <!-- <b-menu-item icon="account-multiple" label="紹介プログラム" :active="activeMenu('/referral')" @click='$router.push( "/referral" ).catch(() => {})'></b-menu-item> -->
          <b-menu-item id="payment" v-if="payment_date" icon="cancel" label="決済を停止する" @click='$router.push( "/payment" ).catch(() => {})'></b-menu-item>
          <b-menu-item id="withdrawal" v-if="Object.keys(business_list).length === 0" icon="close" label="アカウントの削除" @click='withdrawal()'></b-menu-item>
          <b-menu-item id="login" icon="exit-to-app" label="ログアウト" @click='$router.push( "/login" ).catch(() => {})'></b-menu-item>
        </b-menu-list>
      </b-menu>

      <b-menu class="box" v-if="admin_doc && admin_doc.notice && admin_doc.notice.notice">
        <b-menu-list label="お知らせ">
          <b-menu-item
            v-for="(notice, index) in admin_doc.notice.notice"
            :key="index"
            :label="notice.title"
            @click="urlClick(notice.url)"
          />
        </b-menu-list>
      </b-menu>

    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'bulma/css/bulma.css'
import Config from '../config.js'
import { Slide } from 'vue-burger-menu'
import moment from 'moment'

export default {
  components: {
    Slide
  },
  computed : {
    // store.state.business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    // store.state.page_docsの監視
    getPageDocsData() {
      return this.$store.getters.page_docs;
    },
    // store.state.page_dataの監視
    getPageData () {
      return this.$store.getters.page_data;
    },
    // store.state.charges_dataの監視
    getChargesData () {
      return this.$store.getters.charges_data;
    },
    destination_url () {
      return Config.DESTINATION_URL;
    },
    payment_date () {
      return this.$store.getters.payment_date;
    },
    last_payment_date () {
      return this.$store.getters.last_payment_date;
    },
  },
  methods: {
    // 入力された日付に31日を加算して返してYMD形式に変換する
    expired (date) {
      return date ? moment(new Date(date).toISOString()).add(31, 'days').format('YYYY-MM-DD') : ''
    },
    // 外部URLページを開く
    urlClick (url){
      window.open(url, '_blank')
    },
    pageUrlCheck () {
      if (this.business_doc
        && this.business_doc['category_group']
        && this.business_doc['category']
        && this.business_doc['business_name']
        && this.business_doc['phone']
        && this.business_doc['email']
        && this.business_doc['zip_code']
        && this.business_doc['region']
        && this.business_doc['city']
        && this.business_doc['street_address']
        )
      {
        return true
      }
      return false
    },
    // 事業所ページ新規作成
    async newBusiness() {
      // swalで注意喚起してキャンセルなら何もしないでそのまま。
      if(this.$route.path !== '/main' && this.$route.path !== '/') {
        const swal = await this.$swal({
          title: '変更内容を保存しましたか？',
          text: 'ページを移動すると、未保存の内容は破棄されます。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ページを移動する',
          cancelButtonText: 'キャンセル'
        })

        // 移動しない
        if(!swal.value) {
          // menu制御のために事業所作成のis-activeを外す
          await document.getElementById('business-new').classList.remove('is-active')
          return
        }
      }

      // storeの中身を削除
      await this.$store.commit('business_data', this.$store.getters.admin_data.default_business_data)
      await this.$store.commit('page_docs', {})
      await this.$store.commit('page_data', {})

      // 事業所選択を新規作成に
      this.selectedBusinessId = await ''
      this.mode = await 'new'

      await this.goPage('business/new')
    },
    async goPage (destination) {
      // 現在のパスを取得
      const path = await this.$route.path

      // 同一のパスの場合はリロード
      if(path === "/" + destination) {
        await location.reload()
        return
      }

      // ページ遷移
      await this.$router.push( "/" + destination ).catch(() => {})
    },
    /**
     * 事業選択時の選択前のpage_id取得用
     */
    onFocus () {
      this.beforeSelectedId = this.selectedId
    },
    /**
     * Firestoreよりデータ取得しstoreに保存
     * business_idがnull：初期に開く
     * business_idが指定：プルダウン選択状態
     */
    async listenData (business_id=null) {
      // UIDにてビジネスデータ取得
      let querySnapshot
      try {
        querySnapshot = await Config.BUSINESS_COLLECTION.where('uid', '==', this.$store.state.user.uid)
          .where('deleted', '==', false)
          .get()

        if (!querySnapshot.size){
            // ビジネスデータがない
            this.display = false
            // ビジネスデータ作成ページへ
            if (this.$route.path === '/main' || this.$route.path === '/') {
              this.$router.push('business').catch(err => { console.log(err)})
            }
            return
        }
      } catch (err) {
        // TODO:アラート出してログアウト？
        console.log(err)
        return
      }

      // ビジネスデータがある場合には１つ目の事業所と求人ページリストを設定
      // プルダウン用のデータ
      let business_list = []
      // ページデータ検索用のID配列
      let business_ids = []
      // ビジネスデータ
      let business_docs = {}

      querySnapshot.forEach(p => {
        business_list.push(
          {
            'id':p.id, // ドキュメントID
            'business_name':p.data().business_name, // 事業所名
            'branch_name': p.data().branch_name, // 支店名
          })
        business_docs[p.id] = p.data()
        business_ids.push(p.id)
      })

      // 事業所データリスト
      this.business_docs = business_docs
      // プルダウンに入れる
      this.business_list = business_list

      if(this.mode !== 'new') {
        // 事業所選択（business_id） or 事業所未選択（初期表示） or 事業所新規作成
        this.$store.dispatch('getPageDocs', business_id || business_ids[0])

        // 選択済みの事業所ID
        this.selectedBusinessId = business_id || business_ids[0]
        // 選択された事業所のデータ
        this.business_doc = business_id ? business_docs[business_id] : business_docs[business_ids[0]]
        // ページデータのリスト
        this.page_docs = this.$store.getters.page_docs

        // charges_data取得(有料事業所のlast_payment,payment_date用)
        this.$store.dispatch('getChargesData', business_id || business_ids[0])
      }
    },
    /**
     * サイドバーメニューアクティブ化
     *
     * @param menu タグ内に仕込んだアクティブ比較用のメニューシンボル
     * @param from ページ遷移前のpath（vue routerで利用）
     * @param to   ページ遷移後のpath（vue routerで利用）
     */
    activeMenu (menu, from=null, to=null) {
      // メニューリストを設定
      if(!(menu instanceof Array)){
        menu = [menu]
      }
      // 選択されたmenuが現在のパスの中にある
      if (menu.indexOf(this.$route.path) !== -1) {
        // routerからページ遷移しない選択時
        if(this.$route.path === '/' && to !== '/' && from === '/') {
          // 掲載情報のメニュをactive化
          document.getElementsByClassName("menu-list")[0].children[0].children[0].click()
          return false
        }
        return true
      }
      return false
    },
    /**
     * 事業所ページ削除
     */
    async remove () {
      let business_id = this.business_doc.business_id
      let result = await this.$swal.fire({
        title: '本当に削除しますか？',
        html:
            this.business_doc.business_name + '<br>' +
            'https://jp.ilb.jobs/' + this.business_doc['business_id'] + '<br>' + '<br>' +
            '事業所ページの全データが削除されます' + '<br>' +
            '削除したページは復旧できません',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "キャンセル",
        confirmButtonColor: '#d33',
        confirmButtonText: '削除する',
      })

      if (result.value) {
        this.isLoading = true

        try {
          // business_dataのdelete
          await this.$store.dispatch('deleteBusinessData', business_id)
          // charges_dataのdeleted
          await this.$store.dispatch('deleteChargesData', business_id)
          // 画面更新
          await location.reload()
        } catch(e) {
          this.$swal('error', '削除エラー：' + e, 'error')
        }
        this.isLoading = false
      }
      // menu制御のために事業所作成のis-activeを外す
      await document.getElementById('remove').classList.remove('is-active')
    },
    /**
     * 退会処理
     */
    async withdrawal () {
      // すべてのIPが存在しないか確認
      if(Object.keys(this.business_docs).length) {
        await this.$swal('warning','全てのページを削除してください','warning')
        return
      }
      // loading
      this.isLoading = true

      const swal = this.$swal
      let result = await swal.fire({
        title: '本当に削除しますか？',
        html:
            'パスワードを入力し「アカウントを削除する」を押してください' + '<br>' + '<br>' +
            '削除したアカウントは復旧できません',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'アカウントを削除する',
        cancelButtonText: 'キャンセル',
        focusConfirm: false,
        preConfirm: (input) => {
          if (!input) {
              swal.showValidationMessage(
                'パスワードを入力してください'
              )
          }
        }
      })

      if (!result.isConfirmed) {
        this.isLoading = false
        // menu制御のために事業所作成のis-activeを外す
        await document.getElementById('withdraw').classList.remove('is-active')
        return
      }

      // 再認証
      const user = await Config.FIREBASE_AUTH.currentUser
      // 再認証のパラメーター
      const credential = await firebase.auth.EmailAuthProvider.credential(
          user.email,
          result.value
      )

      // ユーザー再認証
      try {
        await user.reauthenticateWithCredential(credential)
      } catch(error) {
        await this.$swal('エラー','パスワードが異なります','error')
        this.isLoading = false
        return
      }

      // ユーザー削除
      try {
        // referral_data のdeleted
        await this.$store.dispatch('deleteReferralData', user.uid)

        // authenticationの無効化
        const userInvalidation = await Config.FIREBASE_FUNCTIONS.httpsCallable('userInvalidation')
        result = await userInvalidation(user.uid)

        if(!result.data.status){
          throw new Error(result.data.error)
        }
        await this.$swal('アカウントを削除しました','ご利用いただき、ありがとうございました','success')
        await this.$router.push('/login').catch(err => { console.log(err)})
      } catch (error) {
        await this.$swal('アカウントを削除できませんでした','再度、アカウントの削除を実施してください','error')
        this.isLoading = false
        return
      }
      this.isLoading = false
      return
    },
    /**
     * 画面リサイズ発火
     */
    handleResize () {
      // resizeのたびにこいつが発火する
      this.window_size = window.innerWidth;
    },
    /**
     * ブラウザ閉じる操作時のエラーメッセージ（IEのみ）
     */
    confirmSave (event) {
      event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
    },
    /*
    * 認証方法の変更メニュー
    * link:
    *
    */
    async changeAuthMethod (provider, method) {
      switch (provider) {
        case 'google':
          await this.changeAuthMethodGoogle(method)
          break;
        case 'password':
          await this.changeAuthMethodEmailAndPassword(method)
          break;
      }
    },
    // google認証を追加
    async changeAuthMethodGoogle (method) {
      switch (method) {
        case 'link':
          await this.swalLinkGoogle()
        break;
        case 'unlink':
          await this.swalUnlinkGoogle()
        break;
      }
    },
    async swalLinkGoogle () {
      const currentUser = await firebase.auth().currentUser;
      const swalParamInput = {
        title: "Google認証を追加する",
        html:
          "ログインしているアカウントで再認証を行います<br>" +
          "登録したパスワードを入力してください<br>" +
          "登録メールアドレス<br>" +
          '<b><span id="swal-input1">' + currentUser.email + '</span></b>' +
          "<br><br>" +
          "パスワード<br>" +
          '<input id="swal-input2" class="swal2-input" maxlength="20">' +
          "<br>",
        showCancelButton: true,
        confirmButtonText: "Google認証を追加する",
        cancelButtonText: "キャンセル",
        allowOutsideClick: false,
        onOpen: function () {
          document.getElementById(
            "swal-input2"
          ).focus()
        },
        preConfirm: () => {
          let errorMessages = []
          const password = document.getElementById("swal-input2").value;

          if (!password) {
            errorMessages.push("パスワードを入力してください")
          }

          if(errorMessages.length) {
            this.$swal.showValidationMessage(errorMessages.join('<br>'))
          }

          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        }
      }

      let result = await this.$swal.fire(swalParamInput);

      if(result.value) {
        const credential = await firebase.auth.EmailAuthProvider.credential(currentUser.email, result.value[1]);

        // 再認証でOKなら、googleのサービスプロバイダー変更画面へ
        try {
          const result = await currentUser.reauthenticateWithCredential(credential)
          console.log('result', result)

          // 再認証が通ったら
          // Googleプロバイダー取得
          const authProvider = new firebase.auth.GoogleAuthProvider();

          // Google 認証画面を表示
          await currentUser.linkWithRedirect(authProvider)
            .then(() => {
              this.$swal('success', 'Google認証を追加いたしました。', 'success');
              // 必要ならここで何某かの処理
              return
            }).catch((error) => {
              // Google 認証失敗時の処理
              this.$swal('error', 'Google認証の追加に失敗しました。しばらく経ってから再度実行してください。' + error, 'error');
            });
        } catch(error) {
            this.$swal('error', '登録されたパスワードと異なります。', 'error')
            return
        }
      }
    },
    async swalUnlinkGoogle () {
      const currentUser = await firebase.auth().currentUser;
      const swalParamInput = {
        title: "Google認証を解除する",
        html:
          "Google認証を解除するボタンを押すと、登録済みのGoogleアカウントの再認証が行われます。<br><br>" +
          "再認証が通るとGoogle認証の解除が行われます。<br>",
        showCancelButton: true,
        confirmButtonText: "Google認証を解除する",
        cancelButtonText: "キャンセル",
        allowOutsideClick: false,
      }

      let result = await this.$swal.fire(swalParamInput);

      if(result.value) {
        const authProvider = new firebase.auth.GoogleAuthProvider();

        await currentUser.reauthenticateWithPopup(authProvider)
          .then(() => {
            // 認証情報再認証成功時の処理
            this.$swal('success', '再認証が成功しました。', 'success')
            // 認証方法変更処理
            currentUser.unlink('google.com').then(() => {
              // 認証方法変更成功時の処理
              this.$swal('success', 'Google認証の解除をいたしました。', 'success')
            }).catch((error) => {
              // 認証方法変更失敗時の処理
              this.$swal('error', 'Google認証の解除が失敗しました。しばらく経ってから再度実行してください。' + error, 'error')
            })
          }).catch((error) => {
            // 認証情報再認証失敗時の処理
            this.$swal('error', '再認証が失敗しました。' + error, 'error')
          })
      }
    },
    // パスワード認証への変更
    async changeAuthMethodEmailAndPassword (method) {
      switch (method) {
        case 'link':
          await this.swalLinkEmailAndPassword()
        break;
        case 'unlink':
          await this.swalUnlinkEmailAndPassword()
        break;
      }
    },
    async swalLinkEmailAndPassword () {
      const currentUser = await firebase.auth().currentUser;
      let swalParamInput = {
        title: "パスワード認証を追加する",
        html:
          "認証用のメールアドレスとパスワードを入力してください。<br>" +
          "メールアドレス<br>" +
          '<input  id="swal-input1" class="swal2-input">' +
          "<br><br>" +
          "パスワード<br>" +
          '<input id="swal-input2" class="swal2-input" maxlength="20">' +
          "<br>",
        showCancelButton: true,
        confirmButtonText: "パスワード認証を追加する",
        cancelButtonText: "キャンセル",
        allowOutsideClick: false,
        onOpen: function () {
          document.getElementById(
            "swal-input1",
            "swal-input2"
          ).focus()
        },
        preConfirm: () => {
          let errorMessages = []
          const email = document.getElementById("swal-input1").value;
          const password = document.getElementById("swal-input2").value;

          if (!email) {
            errorMessages.push("メールアドレスを入力してください")
          }
          if(!/^([a-z0-9_\-.+]+)@([a-z0-9_\-.]+\.[a-z0-9]{2,})$/.test(email)) {
            errorMessages.push("正しいメールアドレスの形式で入力してください");
          }
          if (!password) {
            errorMessages.push("パスワードを入力してください")
          }

          if(errorMessages.length) {
            this.$swal.showValidationMessage(errorMessages.join('<br>'))
          }

          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        }
      }

      let result = await this.$swal.fire(swalParamInput)

      if (result.value) {
        try {
          const credential = await firebase.auth.EmailAuthProvider.credential(result.value[0], result.value[1]);

          await currentUser.linkWithCredential(credential)
          .then((usercred) => {
            const user = usercred.user;
            console.log("Account linking success", user);
            // 認証方法変更成功時の処理
            this.$swal('success', 'パスワード認証追加いたしました。', 'success')
          }).catch((error) => {
            // 認証方法変更失敗時の処理
            this.$swal('error', 'パスワード認証の追加に失敗しました。しばらく経ってから再度実行してください。' + error, 'error')
          });
        } catch(error) {
            // 認証情報再認証失敗時の処理
            this.$swal('error', '再認証が失敗しました。' + error, 'error')
        }
      }
    },
    async swalUnlinkEmailAndPassword () {
      const currentUser = await firebase.auth().currentUser;
      let swalParamInput = {
        title: "パスワード認証を解除する",
        html:
          "ログインしているアカウントで再認証を行います<br>" +
          "登録したパスワードを入力してください<br>" +
          "登録メールアドレス<br>" +
          '<b><span id="swal-input1">' + currentUser.email + '</span></b>' +
          "<br><br>" +
          "パスワード<br>" +
          '<input id="swal-input2" class="swal2-input" maxlength="20">' +
          "<br>",
        showCancelButton: true,
        confirmButtonText: "パスワード認証を解除する",
        cancelButtonText: "キャンセル",
        allowOutsideClick: false,
        onOpen: function () {
          document.getElementById(
            "swal-input2"
          ).focus()
        },
        preConfirm: () => {
          let errorMessages = []
          const password = document.getElementById("swal-input2").value;

          if (!password) {
            errorMessages.push("パスワードを入力してください")
          }

          if(errorMessages.length) {
            this.$swal.showValidationMessage(errorMessages.join('<br>'))
          }

          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        }
      }

      let result = await this.$swal.fire(swalParamInput)

      if (result.value) {
        const credential = await firebase.auth.EmailAuthProvider.credential(currentUser.email, result.value[1]);

        await currentUser.reauthenticateWithCredential(credential)
          .then(() => {
            // 認証情報再認証成功時の処理
            this.$swal('success', '再認証が成功しました。', 'success')
            // 認証方法変更処理
            currentUser.unlink('password').then(() => {
              // 認証方法変更成功時の処理
              this.$swal('success', 'パスワード認証を解除いたしました。', 'success')
            })
            .catch((error) => {
              // 認証方法変更失敗時の処理
              this.$swal('error', 'パスワード認証の解除に失敗しました。しばらく経ってから再度実行してください。' + error, 'error')
            })
        })
        .catch((error) => {
          // 認証情報再認証失敗時の処理
          this.$swal('error', '再認証が失敗しました。' + error, 'error')
        })
      }
    },
    // ここまで認証系の処理
  },
  data () {
    return {
      id: '',
      referral_flg: '',
      window_size: window.innerWidth,
      title: {},
      business_title :{},
      business_docs:{},
      business_doc: {},
      business_list:[],
      page_docs:{},
      page: {},
      ads_doc: {},
      charges_doc: {},
      sales_doc:{},
      admin_doc:{},
      selectedBusinessId: this.$store.getters.business_data ? this.$store.getters.business_data.business_id : '',
      // payment_date: '',
      // last_payment_date: '',
      isLoading: false,
      isFullPage: true,
      beforeSelectedId: this.$store.getters.business_data ? this.$store.getters.business_data.business_id : '', // プルダウン選択前のID取得用
      mode: '',
      googleAuthProvider: false,
      passwordAuthProvider: false,
    }
  },
  watch: {
    // Store.State が変更されたら、watchが検知する
    getBusinessData(newValue) {
      this.business_doc = newValue;
      // プラン変更などのbusiness_data変更時にcharges_data読み込み
      if (newValue && newValue.business_id) {
        this.$store.dispatch('getChargesData', newValue.business_id)
      }
      // this.listenChargesData(this.business_doc.business_id)
    },
    // Store.State が変更されたら、watchが検知する
    getPageDocsData(newValue) {
      this.page_docs = newValue;
    },
    getPageData(newValue) {
      if(newValue && !Object.keys(newValue).length) {
        // page_dataが空の場合、初期値を入れる
        this.$store.commit('page_data', this.$store.getters.admin_data.default_page_data)
        return
      }
      this.page = newValue;

      // page_dataが変更された際の各データ取得
      if (newValue && newValue.page_id) {
        // ads_dataをfirestoreから取得してstateに入れる
        this.$store.dispatch('getAdsData', newValue.page_id)
        // sales_dataをfirestoreから取得してstateに入れる
        this.$store.dispatch('getSalesData', newValue.page_id)
      }
    },
    // 3. Store.State が変更されたら、watchが検知する
    getChargesData(newValue) {
      this.charges_doc = newValue;
    },
    // TODO:ここですべて入れ直さないといけない！！
    selectedBusinessId: async function (newVal, oldVal) {
      if(newVal !== oldVal) {
        this.business_doc = await this.business_docs[newVal]
        // business_docがない場合（不正なIDアクセス含む）は、mainにリダイレクト
        if(!this.business_doc) {
          this.$router.push('/main')
          return
        }
        // 事業所選択したら、事業所データをstateに入れる
        this.$store.commit('business_data', await this.business_docs[newVal])
        //  ページリストをstateに入れる
        this.$store.dispatch('getPageDocs', newVal)
        //  決済リストをstateに入れる
        this.$store.dispatch('getChargesDocs', newVal)
        // page_idはリセットさせて、求人一覧を表示させるようにする
        this.$store.commit('page_data', {})

        // 開いているページがpageの場合のみmainに転送
        switch(this.$route.path) {
          case '/page' : // 求人新規作成ページを開いていて、プルダウン選択時を変更された場合
            this.$router.push('/main');
            break;
        }

        /**
         * いったん残す。事業所プルダウン変更時にそのまま画面が使える機能
         */
        // is-activeのclassを探し、そのエレメントのclassを削除、再度、id=businessにclass=is-activeを付与する
        const matches = document.getElementsByClassName("is-active");
        for (let i = 0; i < matches.length; i++) {
          matches[i].classList.remove("is-active");
        }

        let path =''
        switch (this.$route.path.slice(1)) {
          case '' :
            path = 'main'
            break
          case 'business/new' :
            if(newVal === '') {
              path = 'business-new'
            } else {
              path = 'business'
            }
            break
          default :
            path = this.$route.path.slice(1)
            break
        }
        if (document.getElementById(path)) {
          await document.getElementById(path).classList.add('is-active')
        }
      }
    },
  },
  async created () {
    // admin_docはサービス共通のためlistenDataではとらずここで取る。
    await this.$store.dispatch('getAdminData')
    // await this.listenAdminData()
    // page_titleを取得
    // await this.getParam()
    await this.$store.dispatch('getPageTitle')
    // business_titleを取得
    // await this.getBusinessParam()
    await this.$store.dispatch('getBusinessTitle')

    // routerで受け取ったbusiness_id
    const business_regex = /[0-9a-zA-Z]{20}/
    const page_regex = /[0-9]{9}/
    let business_id = ''

    if(business_regex.test(this.$route.params.id) && this.$route.params.id) {
      // 事業所ID直指定
      business_id = this.$route.params.id;
    } else if (page_regex.test(this.$route.params.id) && this.$route.params.id) {
      // page_dataを更新
      this.$store.dispatch('getPageData', this.$route.params.id)
      // 更新されたpage_dataからbusiness_idを取得
      business_id = this.$store.getters.page_data.business_id
    } else if (this.$route.params.id === 'new') {
      // 事業所新規作成
      this.mode = this.$route.params.id
    } else {
      business_id = Object.keys(this.$store.getters.business_data).length ? this.$store.getters.business_data.business_id : ''
    }

    // メニューに各データ取得して設定
    await this.listenData(business_id);

    // ブラウザ挙動のアラート
    // window.addEventListener("beforeunload", this.confirmSave);
    window.addEventListener('resize', this.handleResize)

  },
  async mounted() {
    // ログインユーザー
    const currentUser = await firebase.auth().currentUser;

    if (currentUser) {
      // 現在の認証方法（provider）を取得
      const data = currentUser.providerData
      const providerList = []
      data.forEach(d => {
        providerList.push(d.providerId)
      })

      // providerにgoogleが含まれていたら
      if(providerList.indexOf('google.com') !== -1) {
        this.googleAuthProvider = true
      }

      if(providerList.indexOf('password') !== -1) {
        this.passwordAuthProvider = true
      }
    }
  },
  // destroyed は Vue インスタンスの破棄した後 に実行されるフックなので､そこでやろうとしても 遅すぎる ため
  beforeDestroy () {
    // window.removeEventListener("beforeunload", this.confirmSave);
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.swal2-styled.swal2-confirm {
  width:inherit
}
.swal2-styled.swal2-cancel {
  width:inherit
}
.bm-burger-button {
/*      position: fixed;*/
      position: sticky !important;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
      cursor: pointer;
}
.bm-burger-bars {
  background-color: #373a47;
}
.line-style {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
}
.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-menu {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: rgb(248, 247, 247) !important;
/*  background-color: rgb(63, 63, 65);  Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}

.bm-overlay {
/*  background: rgba(0, 0, 0, 0.3); */
  background: rgba(0, 0, 0, 0) !important;
}
.bm-item-list {
  color: #b8b7ad;
  margin-left: 10%;
  font-size: 20px;
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}
.bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: white;
}
.navbar-brand, .navbar-tabs {
    min-height: 5.25rem !important;
    padding-left: 1rem !important;
}
html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 0rem !important;

}
@media screen and (max-width: 770px) {
  .mobile-margin {
    margin-top: 2rem !important;
  }
}
.business-url {
  max-width: 80%;
}
.business-url-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
