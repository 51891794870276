<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu />
      </div>
      <div class="column" v-if="Object.keys(business_doc).length">
        <div class="pb-3">
          <h1 class="is-size-4 has-text-weight-bold py-1">
            {{ business_doc['business_name'] }}
            &nbsp;
            {{ business_doc['branch_name'] }}
          </h1>
          <p class="">
            求人ページの一覧（{{ Object.keys(page_docs).length }}件）
          </p>
          <b-message v-if="['create', 'edit'].indexOf(page_type) !== -1" type="is-info is-light mb-3">
            <p>
                求人ページを{{ page_type === 'create' ? `作成` : `更新`}}いたしました
            </p>
            <p>
                URL：{{ destination_url + '/page/' +this.$store.getters.page_data.page_id }}
            </p>
          </b-message>
          <b-message  v-if="this.$store.getters.prevRoute === '/business/new'" type="is-info is-light mb-3">
            <p>
                事業所ページを新規作成いたしました
            </p>
            <p>URL：
              <a class="mb-1" @click="urlClick(destination_url + '/page/' + this.$store.getters.business_data.business_id)">{{ destination_url + '/page/' + this.$store.getters.business_data.business_id }}</a>
            </p>
          </b-message>
        </div>
        <div v-for="(page, key) in page_docs" :key="key" :value="page">
          <div class="card mb-2">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <div class="columns">
                    <div class="column">
                      <p class="is-size-4">
                        {{ page['job_type']}}：{{ employmentTypeJa(page['employment_type']) }}
                      </p>
                    </div>
                  </div>
                  <div class="columns">
                    <!-- 1列目 -->
                    <div class="column">
                      <b-switch id="public"
                        v-model="page['public']"
                        @input="autoSave(page)"
                        >{{ page['public'] ? `公開中` : `非公開` }}
                      </b-switch>
                    </div>
                    <!-- 2列目 -->
                    <div class="column px-0">
                      {{ toYMD(page['date']) }} 更新
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <p>URL：
                        <a class="mb-1" @click="urlClick(destination_url + '/page/' + page['page_id'])">{{ destination_url + '/page/' + page['page_id'] }}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="media-content has-text-right is-size-5">
                  <p><a class="mb-1" @click="goPage('page', page)">ページの編集</a></p>
                  <p><a class="mb-1" @click="goPage('ad', page)">Google広告の設定</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-1 pb-2 has-text-centered">
          <div class="mt-1">
            <b-button
              label="求人ページを新規作成する"
              class="button is-info"
              @click="goPage('page', {})"
              icon-left="plus-circle-outline"
              size="is-large"
              >
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import Config from '../config.js'
import moment from 'moment'

export default {
  components: {
    Menu,
  },
  computed : {
    // 1. computed で、Storeから gettersを取得する
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getPageDocsData() {
      return this.$store.getters.page_docs;
    },
  },
  methods: {
    // 年月日に変更
    toYMD (date) {
      return moment(new Date(date).toISOString()).format("YYYY-MM-DD")
    },
    // 外部URLページを開く
    urlClick (url){
      window.open(url, '_blank')
    },
    // 求人ページの各メニューへ遷移
    goPage (destination, page) {
      // stateのpage_dataに入れる
      this.$store.commit('page_data', page)

      // その後に、各ページに転送
      this.$router.push( "/" + destination ).catch(() => {})
    },
    async autoSave (page) {
      // swalで確認してからpage_data更新
      let result = await this.$swal.fire(
        {
        title: (page.public ? `公開` : `非公開`) + 'へ変更',
        html:
            page.job_type + '：' + this.employmentTypeJa(page.employment_type) + '<br>' +
            'https://jp.ilb.jobs/page/' + page.page_id + '<br>' + '<br>' +
            'こちらの求人ページを' + (page.public ? `公開` : `非公開`) + 'しますか？',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "キャンセル",
        confirmButtonColor: (page.public ? '#167DF0' : '#d33'),
        confirmButtonText: (page.public ? `公開` : `非公開`) + `する`
      })

      if (result.value) {
        this.isLoading = true
        try {

          await this.$store.dispatch('updatePageData', {
            doc_id : page.page_id,
            data : {
              public : page['public']}
          })
        } catch (error) {
          console.log(error)
          this.$swal("データ更新エラー", "エラー内容：" + error, "error");
        }
        this.isLoading = false
        return
      }

      // キャンセルの場合
      page['public'] = !page['public']
    }
  },
  data () {
    return {
      business_doc: this.$store.getters.business_data || {},
      page_docs: this.$store.getters.page_docs || {},
      destination_url : Config.DESTINATION_URL,
      business_title : this.$store.getters.business_title,
      page_type : '', // '' or edit or create TODOどうやって渡すか？
    }
  },
  watch : {
    // Store.State が変更されたら、watchが検知する
    getBusinessData(newValue) {
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
    // Store.State が変更されたら、watchが検知する
    getPageDocsData(newValue) {
      if(newValue === undefined) return
      this.page_docs = newValue;
    },
  },
  mounted () {
    const instance = this.$store
    setTimeout(function() {
      instance.commit('prevRoute', '')
    }, 5000);
  }
}

</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
img {
  border: solid 1px #CCCCCC;
}
</style>
