<template>
  <section v-if="business_doc" class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu />
      </div>

      <div class="column">
        <h2 class="is-size-4 has-text-weight-bold py-5">クレジットカード情報を変更する</h2>

        <p>対象事業所</p>
        <p class="mb-5">{{ business_doc.business_name }} {{ business_doc.branch_name }}</p>

        <h6>現在のカード番号</h6>
        <p class="is-size-3">●●●● {{ last4 }}（{{ brand }}）</p>
        <hr>
        <p>利用可能なクレジットカード</p>
        <img src="@/assets/payment_icon/VISA.png" alt="VISA" width="10%">
        <img src="@/assets/payment_icon/MasterCard.png" alt="MasterCard" width="10%">
        <img src="@/assets/payment_icon/JCB.png" alt="JCB" width="10%">
        <img src="@/assets/payment_icon/AMERICAN EXPRESS.png" alt="AMERICAN EXPRESS" width="10%">
        <img src="@/assets/payment_icon/DinersClub.png" alt="DinersClub" width="10%">

        <b-field label="カード番号（ハイフンなし）">
          <div class="col s12 card-element">
            <div class="error">{{stripeValidationErrorCardnumber}}</div>
            <div id="card-number-element" class="input-value"></div>
          </div>
        </b-field>

        <b-field label="有効期限">
          <div class="col s6 card-element">
            <div class="error">{{stripeValidationErrorExpired}}</div>
            <div id="card-expiry-element"></div>
          </div>
        </b-field>
        <div class="comment">カードに記載されている通り入力してください</div>

        <b-field label="セキュリティーコード（CVC）">
          <div class="col s6 card-element">
              <div id="card-cvc-element"></div>
          </div>
        </b-field>
        <div class="comment">セキュリティコードとは、クレジットカードの裏面に記載されている3桁の数字です。</div>
        <div class="comment">AMEXの場合は、カード表面右上にある4桁の数字です。</div>

        <div class="col s12 place-order-button-block">
          <button v-if='success === false' class="btn col s12 #e91e63 pink has-text-weight-bold" @click="placeOrderButtonPressed('page')">カード情報を変更する</button>
          <b-button v-if="success === 'loading'" loading>Loading</b-button>
          <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import {loadStripe} from '@stripe/stripe-js'
import Config from '../config.js'


export default {
  components: {
    Menu,
  },
  data () {
    return {
      business_doc: this.$store.getters.business_data || {},
      charges_doc :this.$store.getters.charges_data || {},
      page : {},
      brand: null,
      last4: null,
      customer_id: null,
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationError: "",
      amount:Config.AMOUNT,
      stripeValidationErrorCardnumber: "",
      stripeValidationErrorExpired: "",
      success:false,
      isLoading: false,
      isFullPage: true,
    }
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    // charges_dataの監視
    getChargesData() {
      return this.$store.getters.charges_data;
    },
  },
  methods: {
    getCustomerId () {
      this.customer_id = this.charges_doc.customer_id
      this.last4 = this.charges_doc.last4
      this.brand = this.charges_doc.brand
    },
    // stripe UIを使って決済画面elementの作成
    createAndMountFormElements() {
      const elementStyles = {
        base: {
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#CFD7E0",
          },
          ":-webkit-autofill": {
            color: "#e39f48",
          },
        },
        invalid: {
          color: "#E25950",

          "::placeholder": {
            color: "red",
          },
        },
      };

      const elementClasses = {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      };

      const elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber", {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardNumberElement.mount("#card-number-element");
      this.cardExpiryElement = elements.create("cardExpiry", {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardExpiryElement.mount("#card-expiry-element");
      this.cardCvcElement = elements.create("cardCvc", {
        style: elementStyles,
        class: elementClasses,
        placeholder: "123",
      });
      this.cardCvcElement.mount("#card-cvc-element");
      this.cardNumberElement.on("change", this.setValidationError);
      this.cardExpiryElement.on("change", this.setValidationError);
      this.cardCvcElement.on("change", this.setValidationError);
    },
    // 入力バリデーション
    setValidationError(event) {
      this.stripeValidationErrorCardnumber =
        event.error && event.error.code === "invalid_number"
          ? event.error.message
          : "";

      if (event.error) {
        switch (event.error.code) {
          case "invalid_expiry_year_past":
            this.stripeValidationErrorExpired = event.error.message;
            break;
          case "invalid_expiry_month_past":
            this.stripeValidationErrorExpired = event.error.message;
            break;
          default:
            this.stripeValidationErrorExpired = "";
            break;
        }
      } else {
        this.stripeValidationErrorExpired = "";
      }
    },
    // 変更するボタン
    async placeOrderButtonPressed(service_name) {
      this.success = "loading";
      this.isLoading = true;
      this.stripe.createToken(this.cardNumberElement).then(result => {
          if (result.error) {
              this.stripeValidationError = result.error.message
              this.success = false
              this.isLoading = false
          } else {
              let stripeObject = {
                  customer_id: this.customer_id,
                  source: result.token,
              }
              this.cardChangeStripe(service_name, stripeObject)
          }
      });
    },
    // カード変更処理
    async cardChangeStripe(service_name, stripeObject) {
      const changeCard =
        Config.FIREBASE_FUNCTIONS.httpsCallable("changePaymentCard");

      let card_change_data = {}
      card_change_data['stripe'] = stripeObject
      card_change_data['business_data'] = this.business_doc
      card_change_data['service_name'] = service_name

      let swal = this.$swal;

      try {
        let result = await changeCard(card_change_data)
        if(result.data.status) {
          this.success = await true
          this.isLoading = await false

          await swal({
              title:'クレジットカード情報を変更しました',
              text:'次回の決済から変更となります。',
              confirmButtonText: '閉じる',
              icon:'success',
          })
        } else {
          await swal({
            title:'カード変更がエラーとなりました',
            html: result.data.error.code
                  + '<br>'
                  + result.data.error.message
                  + '<br>',
            confirmButtonText: '閉じる',
            icon:'error',
          })

          this.success = await true
          this.isLoading = await false
        }
      } catch (e) {
        await this.$swal({
          title:'カード情報変更がエラーとなりました',
          text:'管理者に連絡してください:' + e.message,
          confirmButtonText: '閉じる',
          icon:'error',
        })
        this.success = await true
        this.isLoading = await false
      }
    },
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
    // charges_dataの監視
    getChargesData(newValue) {
      this.charges_doc = newValue;
      if(this.charges_doc && this.charges_doc.page && this.charges_doc.page.payment_date) {
        // 事業所選択後、決済データの変更があった場合に再度カード情報取得
        this.getCustomerId();
        return;
      }

      // 有料プラン誘導への画面表示
      this.$swal('クレジットカード変更', 'こちらの事業所は有料プランではありません。プラン変更画面へ移動します。', 'info').then(() => {
        this.$router.push('/payment').catch(() => {})
      })
    },
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY)
    this.createAndMountFormElements()
    this.getCustomerId()
  },
}
</script>

<style scoped>
.payment-form .comment {
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.payment-form .error {
  color: red;
}
.payment-form {
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid #ececec;
  padding: 12px;
  margin-bottom: 8px;
}
.payment-form h5 {
  margin: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.2rem;
}
.payment-form h6 {
  margin-bottom: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.5rem;
}
.card-element {
  margin-top: 10px;
}
#card-number-element,
#card-expiry-element,
#card-cvc-element {
  background: white;
  padding: 5px;
  border: 2px solid #ececec;
  height: 30px;
}

.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}

button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
.notification.is-link.is-light {
  padding: 1ex;
}
.notification.is-danger.is-light {
  padding: 1ex;
}
</style>
