<template>
  <!-- storeからの反映が少し遅れるのでifで待つ -->
  <section v-if="business_doc.business_image">
    <label class="label has-text-weight-bold">&nbsp;事業所の画像</label>
    <p v-html="business_title.business_image_description"></p>
    <div class="content" v-for="key of business_doc.business_image.length" :key="key">
      <div v-if="updateFlg" class="field pb-3">
        <div class="columns">
          <div class="column is-four-fifths">
            <div class="columns">
              <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
                <img :src="business_doc.business_image[key-1]" width="100px">
              </div>
              <div class="column">
                  <photo-resize
                    :image-title="'business_image'"
                    :doc-field-image-title="'image'"
                    :array-num="key-1"
                    :doc-field-title="'business_image'"
                    :business-doc-id="business_doc.business_id"
                    :src="business_doc.business_image[key-1]"
                    @businessimage="imgUpdate"
                    ref="businessimage"/>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="columns">
              <div class="column is-half is-align-self-flex-end is-justify-content-center">
                <b-button
                  v-if="(key-1) !== 0"
                  type="is-ghost"
                  icon-right="arrow-up-bold-outline"
                  size="is-large"
                  @click="menuMoveBusiness('business_image', (key-1), 'up')"
                />
              </div>
              <div class="column is-align-self-flex-end is-justify-content-center">
                <b-button
                  v-if="(key-1) !== (business_doc.business_image.length - 1)"
                  type="is-ghost"
                  icon-right="arrow-down-bold-outline"
                  size="is-large"
                  @click="menuMoveBusiness('business_image', (key-1), 'down')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </section>
</template>

<script>
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  data () {
    return {
      business_doc: this.$store.getters.business_data || {
        business_image: []
      },
      isOpen: true,
      updateFlg: true,
      imageFile: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods :{
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.business_doc, firstProp, this.business_doc[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.business_doc[firstProp], secondProp, this.business_doc[firstProp][secondProp].trim())
      } else {
        this.$set(this.business_doc[firstProp][secondProp], thirdProp, this.business_doc[firstProp][secondProp][thirdProp].trim())
      }
    },
    // photoResize→親へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.businessimage).map(async key => {
        this.$refs.businessimage[key].reset()
      })
    }
  },
  computed : {
    business_title () {
      return this.$store.getters.business_title
    },
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data && Object.keys(this.$store.getters.business_data).length
      ? this.$store.getters.business_data
      : this.$store.getters.admin_data.default_business_data
    },
  },
  watch : {
    // business_dataの監視
    getBusinessData(newValue) {
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
  },
}
</script>
