<template>
  <section>
    <div>
      <h2 class="is-size-4 has-text-weight-bold pb-0">募集要項</h2>
    </div>
    <hr class="py-0 my-1">
    <div class="mb-5">
      <p v-html="title.recruitment_details_description"></p>
    </div>
    <div class="field">
      <label class="label has-text-weight-bold" for="job_type"><span class="tag is-danger">必須</span>&nbsp;{{ title.job_type }}</label>
      <p v-html="title.job_type_description"></p>
      <b-input id="job_type" v-model="page.job_type"  maxlength="15" @blur="trim('job_type')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="employment_type"><span class="tag is-danger">必須</span>&nbsp;{{ title.employment_type }}</label>
      <p v-html="title.employment_type_description"></p>
      <div class="block">
        <b-radio v-model="page.employment_type"
            name="employment_type"
            native-value="FULL_TIME">
            正社員
        </b-radio>
        <b-radio v-model="page.employment_type"
            name="employment_type"
            native-value="PART_TIME">
            アルバイト・パート
        </b-radio>
        <b-radio v-model="page.employment_type"
            name="employment_type"
            native-value="CONTRACTOR">
            契約社員
        </b-radio>
        <b-radio v-model="page.employment_type"
            name="employment_type"
            native-value="OUTSOURCE"
            >
            業務委託
        </b-radio>
        <b-radio v-model="page.employment_type"
            name="employment_type"
            native-value="INTERN"
            >
            インターン
        </b-radio>
        <b-radio v-model="page.employment_type"
            name="employment_type"
            native-value="VOLUNTEER"
            >
            ボランティア
        </b-radio>
      </div>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="job_description"><span class="tag is-danger">必須</span>&nbsp;{{ title.job_description }}</label>
      <p v-html="title.job_description_description"></p>
      <b-input id="job_description" v-model="page.job_description" type="textarea" maxlength="300" class="pb-1" @blur="trim('job_description')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="salary_unit"><span class="tag is-danger">必須</span>&nbsp;{{ title.salary_unit }}</label>
      <p v-html="title.salary_unit_description"></p>
      <div class="columns">
        <div class="column is-2">
          <b-field>
            <b-select id="salary_unit" v-model="page.salary_unit" placeholder="選択してください">
              <option v-for="(value, key) in salary_unit_list" :key="key" :value="value">{{ getSalaryTypeJa(value) }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2 pr-0 pl-0">
          <b-input id="salary_min" v-model.number="page.salary_min" class="pb-1" type="number"></b-input>
        </div>
        <div class="column is-align-self-center is-1 pl-2">
          円
        </div>
        <div class="column is-align-self-center mr-5">
          <b-checkbox v-model="over_check"> 以上 </b-checkbox>
        </div>
        <div class="column is-align-self-center">
          〜
        </div>
          <div class="column is-2 pr-0 pl-0">
          <b-input id="salary_max" v-model.number="page.salary_max" class="pb-1" :disabled="salary_max_disabled" type="number"></b-input>
        </div>
        <div class="column is-align-self-center pl-2">
          円
        </div>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="salary_detail">{{ title.salary_detail }}</label>
        <p v-html="title.salary_detail_description"></p>
        <b-input id="salary_detail" v-model="page.salary_detail" type="textarea" maxlength="100" class="pb-1" @blur="trim('salary_detail')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="work_hours">{{ title.work_hours }}</label>
        <p v-html="title.work_hours_description"></p>
        <b-input id="work_hours" v-model="page.work_hours" type="textarea" maxlength="30" class="pb-1" @blur="trim('work_hours')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="telecommute">{{ title.telecommute }}</label>
        <p v-html="title.telecommute_description"></p>
        <div class="block">
          <b-radio v-model="page.telecommute"
              name="telecommute"
              native-value="true">
              可
          </b-radio>
          <b-radio v-model="page.telecommute"
              name="telecommute"
              native-value="false">
              不可
          </b-radio>
        </div>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="job_keywords">仕事の特長（最大10個）</label>
        <p v-html="title.job_keywords_description"></p>
        <div v-if="page.job_keywords">
          <div v-for="key in [1,3,5,7,9]" :key="key" class="columns">
            <div class="column">
              <b-field class="mb-0">
                <b-input
                  :id="'job_keywords_' + key"
                  name='`jobkeywords${key}`'
                  maxlength="10"
                  type="input"
                  v-model="page.job_keywords[key-1]"
                  data-vv-as="仕事の特徴"
                  @blur="trim('job_keywords', key-1)"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field class="mb-0">
                <b-input
                  :id="'job_keywords_' + (key + 1) "
                  name='`jobkeywords${key+1}`'
                  maxlength="10"
                  type="input"
                  v-model="page.job_keywords[key]"
                  data-vv-as="仕事の特徴"
                  @blur="trim('job_keywords', key)"
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="requirement">{{ title.requirement }}</label>
        <p v-html="title.requirement_description"></p>
        <b-input id="requirement" v-model="page.requirement" type="textarea" maxlength="100" class="pb-1" @blur="trim('requirement')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="holiday">{{ title.holiday }}</label>
        <p v-html="title.holiday_description"></p>
        <b-input id="holiday" v-model="page.holiday" type="textarea" maxlength="30" class="pb-1" @blur="trim('holiday')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="benefits">{{ title.benefits }}</label>
        <p v-html="title.benefits_description"></p>
        <b-input id="benefits" v-model="page.benefits" type="textarea" maxlength="100" class="pb-1" @blur="trim('benefits')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="job_detail_title">{{ title.job_detail_title }}</label>
        <p v-html="title.job_detail_title_description"></p>
        <b-input id="job_detail_title" v-model="page.job_detail_title" maxlength="15" class="pb-1" @blur="trim('job_detail_title')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="job_detail">{{ title.job_detail }}</label>
        <p v-html="title.job_detail_description"></p>
        <b-input id="job_detail" v-model="page.job_detail" type="textarea" maxlength="500" class="pb-1" @blur="trim('job_detail')"></b-input>
      </div>

      <JobImage
        @imgUpdate="imgUpdate"
        ref="jobimage"
      />

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="job_movie">{{ title.job_movie }}</label>
        <p v-html="title.job_movie_description"></p>
        <b-input id="job_movie" v-model="page.job_movie" class="pb-1" @blur="trim('job_movie')"></b-input>
      </div>

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="contact_message">{{ title.contact_message }}</label>
        <p v-html="title.contact_message_description"></p>
        <b-input id="contact_message" v-model="page.contact_message" type="textarea" maxlength="300" class="pb-1" @blur="trim('contact_message')"></b-input>
      </div>

    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import JobImage from '@/components/page/JobImage.vue'

export default {
  components: {
    JobImage
  },
  data () {
    // console.log('dataのgetPageData', this.$store.getters.page_data, this.$store.getters.page_data.salary_display_type);
    return {
      page :this.$store.getters.page_data,
      isLoading: false,
      isFullPage: true,
      salary_unit_list : ['HOUR','DAY','MONTH','YEAR'],
      over_check : this.$store.getters.page_data && this.$store.getters.page_data.salary_display_type === 'MIN' ? true : false,
      salary_max_disabled : this.$store.getters.page_data && this.$store.getters.page_data.salary_display_type === 'MIN' ? true : false
    }
  },
  computed : {
    title () {
      return this.$store.getters.title
    },
    // page_dataの監視
    getPageData() {
      // console.log('computedのgetPageData', this.$store.getters.page_data);
      return this.$store.getters.page_data
    },
  },
  watch : {
    // page_dataの監視
    getPageData(newValue) {
      if(newValue === undefined) return
      this.page = newValue;
    },
    over_check(newValue) {
      if(newValue) {
        this.page.salary_max = ''
        this.page.salary_display_type = 'MIN'
        this.salary_max_disabled = true
        return
      }
      this.page.salary_display_type = 'FIXED'
      this.salary_max_disabled = false
    },
    // 画面上でのpageの変更をstoreにcommit
    page: {
      handler: function (newVal) {
        // console.log('JobDescription.vue page updated', newVal)
        this.$store.commit('page_data', newVal)
      },
      deep: true
    }
  },
  methods: {
    // photoResize→親へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.$emit('imgUpdate', imageFileData);
    },
    // 親→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      // console.log('JobDescription', this.$refs.jobimage);
      this.$refs.jobimage.reset()
    },
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.page, firstProp, this.page[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.page[firstProp], secondProp, this.page[firstProp][secondProp].trim())
      } else {
        this.$set(this.page[firstProp][secondProp], thirdProp, this.page[firstProp][secondProp][thirdProp].trim())
      }
    },
    /**
     * 給与タイプ
     */
    getSalaryTypeJa (type) {
      switch(type) {
        case 'HOUR':
          return '時給'
        case 'DAY':
          return '日給'
        case 'MONTH':
          return '月給'
        case 'YEAR':
          return '年俸'
      }
    },
  },
  mounted() {
    // page.salary_max=0は表示の時に空欄にする
    if(this.page.salary_max === 0) {
      this.page.salary_max = ''
    }
  }
}
</script>

<style>
</style>
