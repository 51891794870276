<template>
  <section v-if="business_doc">
    <div v-if="plan_change">
      <Complete :plan_change="plan_change" />
    </div>
    <div v-else>
      <h2 class="is-size-4 has-text-weight-bold py-5">
        {{  charges_doc && charges_doc.page && !charges_doc.page.payment_date ? `決済を再開する` : `機能制限の解除（月額利用料の決済）`}}
      </h2>
      <p>対象事業所</p>
      <p class="mb-5 is-size-3">{{ business_doc["business_name"] }} {{ business_doc["branch_name"] }}</p>

      <p class="has-text-danger">決済金額</p>
      <p class="is-size-3">月額{{ amount.toLocaleString() }}円（税込）</p>
      <!-- <p>10%対象(税抜)：{{ tax_excluded_price.toLocaleString() }}円</p>
      <p>10%対象消費税：{{ tax.toLocaleString() }}円（税込）</p> -->

      <!-- 決済停止されている場合(mountをしないと表示されなくなるのであえてdisplay:noneで対応) -->
      <div :class="{ noDisplay: charges_doc && charges_doc.page && !charges_doc.page.payment_date }">
        <hr>
        <p>利用可能なクレジットカード</p>
        <img src="@/assets/payment_icon/VISA.png" alt="VISA" width="10%">
        <img src="@/assets/payment_icon/MasterCard.png" alt="MasterCard" width="10%">
        <img src="@/assets/payment_icon/JCB.png" alt="JCB" width="10%">
        <img src="@/assets/payment_icon/AMERICAN EXPRESS.png" alt="AMERICAN EXPRESS" width="10%">
        <img src="@/assets/payment_icon/DinersClub.png" alt="DinersClub" width="10%">

        <b-field label="カード番号（ハイフンなし）">
          <div class="col s12 card-element">
            <div class="error">{{ stripeValidationErrorCardnumber }}</div>
            <div id="card-number-element" class="input-value"></div>
          </div>
        </b-field>

        <b-field label="有効期限">
          <div class="col s6 card-element">
            <div class="error">{{ stripeValidationErrorExpired }}</div>
            <div id="card-expiry-element"></div>
          </div>
        </b-field>
        <div class="comment">カードに記載されている通り入力してください</div>

        <b-field label="セキュリティーコード（CVC）">
          <div class="col s6 card-element">
            <div id="card-cvc-element"></div>
          </div>
        </b-field>
        <div class="comment">
          セキュリティコードとは、クレジットカードの裏面に記載されている3桁の数字です。
        </div>
        <div class="comment">AMEXの場合は、カード表面右上にある4桁の数字です。</div>

        <p class="py-3">
          <a href="https://r.ilb.net/tos-ja" target="_blank" rel="noopener"
            >利用規約を見る<b-icon icon="launch" size="is-small"></b-icon
          ></a>
        </p>
      </div>

      <div v-if="charges_doc && charges_doc.page && !charges_doc.page.payment_date">
        <div class="col s12 place-order-button-block">
          <p>支払いを再開すると次回以降の月額利用料の決済が再開されます。</p>
          <b-button
            v-if="success === false"
            class="btn col s12 #e91e63 pink"
            type="is-success has-text-weight-bold"
            @click="repaymentButtonPressed()"
          >
            決済を再開する
          </b-button>
          <b-button v-if="success === 'loading'" loading>Loading</b-button>
          <b-loading
            :is-full-page="isFullPage"
            :active.sync="isLoading"
          ></b-loading>
        </div>
      </div>
      <div v-else>
        <div class="col s12 place-order-button-block">
          <b-button
            v-if="success === false"
            class="btn col s12 #e91e63 pink"
            type="is-success has-text-weight-bold"
            @click="placeOrderButtonPressed('page')"
          >
            利用規約に同意の上、決済する
          </b-button>
          <b-button v-if="success === 'loading'" loading>Loading</b-button>
          <b-loading
            :is-full-page="isFullPage"
            :active.sync="isLoading"
          ></b-loading>
        </div>
        <p class="help has-text-centered mt-0">1ヶ月間ごとの自動更新となります</p>
        <br>
        <p class="help has-text-centered mt-0">登録番号：T6010001124948</p>
        <p class="help has-text-centered mt-0">株式会社ILB</p>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import {loadStripe} from '@stripe/stripe-js'
import Config from '../../config.js'
import Complete from '@/components/payment/Complete.vue'

export default {
  components: {
    Complete
  },
  data () {
    return {
      business_doc: this.$store.getters.business_data,
      charges_docs: this.$store.getters.charges_docs,
      charges_doc: {},
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationErrorCardnumber: "",
      stripeValidationErrorExpired: "",
      amount: Config.AMOUNT,
      success: false,
      isLoading: false,
      isFullPage: true,
      plan_change: '',
    }
  },
  computed: {
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data;
    },
    getChargesDocsData() {
      return this.$store.getters.charges_docs;
    },
    getChargesData() {
      return this.$store.getters.charges_data;
    },
    tax () {
      return this.amount - (this.amount / (1 + Config.TAX_RATE));
    },
    tax_excluded_price () {
      return this.amount - this.tax;
    },
  },
  methods: {
    // stripe UIを使って決済画面elementの作成
    createAndMountFormElements() {
      const elementStyles = {
        base: {
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#CFD7E0",
          },
          ":-webkit-autofill": {
            color: "#e39f48",
          },
        },
        invalid: {
          color: "#E25950",

          "::placeholder": {
            color: "red",
          },
        },
      };

      const elementClasses = {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      };

      const elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber", {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardNumberElement.mount("#card-number-element");
      this.cardExpiryElement = elements.create("cardExpiry", {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardExpiryElement.mount("#card-expiry-element");
      this.cardCvcElement = elements.create("cardCvc", {
        style: elementStyles,
        class: elementClasses,
        placeholder: "123",
      });
      this.cardCvcElement.mount("#card-cvc-element");
      this.cardNumberElement.on("change", this.setValidationError);
      this.cardExpiryElement.on("change", this.setValidationError);
      this.cardCvcElement.on("change", this.setValidationError);
    },
    // 入力バリデーション
    setValidationError(event) {
      this.stripeValidationErrorCardnumber =
        event.error && event.error.code === "invalid_number"
          ? event.error.message
          : "";

      if (event.error) {
        switch (event.error.code) {
          case "invalid_expiry_year_past":
            this.stripeValidationErrorExpired = event.error.message;
            break;
          case "invalid_expiry_month_past":
            this.stripeValidationErrorExpired = event.error.message;
            break;
          default:
            this.stripeValidationErrorExpired = "";
            break;
        }
      } else {
        this.stripeValidationErrorExpired = "";
      }
    },
    // 決済するボタン
    async placeOrderButtonPressed(service_name) {
      this.success = "loading";
      this.isLoading = true;
      this.stripe.createToken(this.cardNumberElement).then((result) => {
        if (result.error) {
          this.stripeValidationError = result.error.message;
          this.success = false;
          this.isLoading = false;
        } else {
          let stripeObject = {
            amount: this.amount,
            source: result.token,
          };
          this.chargeStripe(service_name, stripeObject);
        }
      });
    },
    // stripe決済処理
    async chargeStripe(service_name, stripeObject) {
      const checkOut =
        Config.FIREBASE_FUNCTIONS.httpsCallable("instantPayment");

      let checkout_data = {};
      checkout_data["stripe"] = stripeObject;
      checkout_data["business_data"] = this.business_doc;
      checkout_data["service_name"] = service_name;

      let swal = this.$swal;

      try {
        let result = await checkOut(checkout_data);
        if (result.data.status) {
          this.success = await true;
          this.isLoading = await false;

          // 完了画面を表示させる
          this.plan_change = 'upgrade';
        } else {
          await swal({
            title: "決済がエラーとなりました",
            html:
              "ご入力いただいたカード情報で決済ができませんでした。" +
              "<br>" +
              "再度お試しください。"+
              "<br>",
            confirmButtonText: "閉じる",
            icon: "error",
          });
          this.success = await true;
          this.isLoading = await false;
        }
      } catch (e) {
        await swal({
          title: "決済がエラーとなりました",
          text: "管理者に連絡してください:" + e.message,
          confirmButtonText: "閉じる",
          icon: "error",
        });
        this.success = await true;
        this.isLoading = await false;
      }
    },
    // 決済再開ボタン
    async repaymentButtonPressed() {
      let swal = this.$swal;
      this.success = "loading";
      this.isLoading = true;
      // chargesデータを変更する
      // 更新のdispatch
      try {
        await this.$store.dispatch('updateChargesData', {
          doc_id : this.business_doc.business_id,
          data : {
            page: {
              // last_payment_dateの日を設定
              payment_date : this.charges_doc.page.last_payment_date.split('/')[2]
            }
          }
        })

        await swal({
          title: "決済が再開されました",
          html:
            "次回以降の決済が再開されました。",
          confirmButtonText: "閉じる",
          icon: "success",
        });
      } catch (e) {
        await swal({
          title: "決済がエラーとなりました",
          html:
            "ご入力いただいたカード情報で決済ができませんでした。" +
            "<br>" +
            "再度お試しください。"+
            "<br>",
          confirmButtonText: "閉じる",
          icon: "error",
        });
      }
      this.success = await true;
      this.isLoading = await false;
    },
  },
  watch: {
    // business_dataの監視
    getBusinessData(newValue) {
      this.business_doc = newValue;
    },
    // charges_docsの監視
    getChargesDocsData(newValue) {
      console.log('upgradeのgetChargesDocsData', newValue)
      this.charges_docs = newValue;
    },
    // charges_dataの監視
    getChargesData(newValue) {
      this.charges_doc = newValue;
    },
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY);
    this.createAndMountFormElements();
  },
}
</script>

<style scoped>
.payment-form .comment {
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.payment-form .error {
  color: red;
}
.payment-form {
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid #ececec;
  padding: 12px;
  margin-bottom: 8px;
}
.payment-form h5 {
  margin: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.2rem;
}
.payment-form h6 {
  margin-bottom: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.5rem;
}
.card-element {
  margin-top: 10px;
}
#card-number-element,
#card-expiry-element,
#card-cvc-element {
  background: white;
  padding: 5px;
  border: 2px solid #ececec;
  height: 30px;
}

.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}

button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
.noDisplay {
  display: none;
}
</style>
