<template>
  <!-- storeからの反映が少し遅れるのでifで待つ -->
  <section>
    <div>
        <h2 class="is-size-4 has-text-weight-bold pb-0">関連サイト</h2>
    </div>
    <hr class="py-0 my-1">
    <div class="mb-5">
      <p v-html="business_title.related_sites_description"></p>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="official_url">
        {{ business_title.official_url }}
      </label>
      <p v-html="business_title.official_url_description"></p>
      <b-input id="official_url" v-model="business_doc.official_url"  class="pb-1" @blur="trim('official_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="blog_url">
        {{ business_title.blog_url }}
      </label>
      <p v-html="business_title.blog_url_description"></p>
      <b-input id="blog_url" v-model="business_doc.blog_url"  class="pb-1" @blur="trim('blog_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="facebook_page_id">
        {{ business_title.facebook_page_id }}
      </label>
      <p v-html="business_title.facebook_page_id_description"></p>
      <b-input id="facebook_page_id" v-model="business_doc.facebook_page_id"  class="pb-1" @blur="trim('facebook_page_id')"></b-input>
    </div>


    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="instagram_id">
        {{ business_title.instagram_id }}
      </label>
      <p v-html="business_title.instagram_id_description"></p>
      <b-input id="instagram_id" v-model="business_doc.instagram_id"  class="pb-1" @blur="trim('instagram_id')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="tiktok_id">
        {{ business_title.tiktok_id }}
      </label>
      <p v-html="business_title.tiktok_id_description"></p>
      <b-input id="tiktok_id" v-model="business_doc.tiktok_id"  class="pb-1" @blur="trim('tiktok_id')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="x_id">
        {{ business_title.x_id }}
      </label>
      <p v-html="business_title.x_id_description"></p>
      <b-input id="x_id" v-model="business_doc.x_id"  class="pb-1" @blur="trim('x_id')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="youtube_channel_id">
        {{ business_title.youtube_channel_id }}
      </label>
      <p v-html="business_title.youtube_channel_id_description"></p>
      <b-input id="youtube_channel_id" v-model="business_doc.youtube_channel_id"  class="pb-1" @blur="trim('youtube_channel_id')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="link_url">
        {{ business_title.link_url }}
      </label>
      <p v-html="business_title.link_url_description"></p>
      <b-input id="link_url" v-model="business_doc.link_url" class="pb-1" @blur="trim('link_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="link_text">
          {{ business_title.link_text }}
      </label>
      <p v-html="business_title.link_text_description"></p>
      <b-input id="link_text" v-model="business_doc.link_text" maxlength="15" class="pb-1" @blur="trim('link_text')"></b-input>
    </div>
  </section>
</template>

<script>

export default {
  data () {
    return {
      business_doc: this.$store.getters.business_data || {
        official_url: '',
        blog_url: '',
        facebook_page_id: '',
        instagram_id: '',
        tiktok_id: '',
        x_id: '',
        youtube_channel_id: '',
        link_url: '',
        link_text: '',
      },
    }
  },
  components: {
  },
  methods :{
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.business_doc, firstProp, this.business_doc[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.business_doc[firstProp], secondProp, this.business_doc[firstProp][secondProp].trim())
      } else {
        this.$set(this.business_doc[firstProp][secondProp], thirdProp, this.business_doc[firstProp][secondProp][thirdProp].trim())
      }
    },
  },
  computed : {
    business_title () {
      return this.$store.getters.business_title
    },
    // business_dataの監視
    getBusinessData() {
      return this.$store.getters.business_data && Object.keys(this.$store.getters.business_data).length
      ? this.$store.getters.business_data
      : this.$store.getters.admin_data.default_business_data
    },
  },
  watch : {
    // business_dataの監視
    getBusinessData(newValue) {
      if(newValue === undefined) return
      this.business_doc = newValue;
    },
  },
}
</script>
